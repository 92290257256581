<template>
  <div class="fill-height">
    <template v-if="isDataLoaded">
      <v-toolbar
        class="bc-outlined-gray bb-1"
        elevation="0"
        style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
        absolute
      >
        <div class="d-flex align-center px-4 w-100">
          <div class="d-flex align-center flex-1-0">
            <v-btn
              :aria-label="$t('Back to all families')"
              :to="{ name: 'FamilySubsidyIndex' }"
              class="mr-3"
              icon="west"
              variant="text"
            />

            <div data-cy="page-title">
              <span
                v-text="subsidy.group_name"
                class="d-inline-block fs-22 fw-600"
              />

              <router-link
                :to="{
                  name: 'GroupShow',
                  params: { groupId: subsidy.group_id },
                  query: { tab: 'profile' },
                }"
                class="ms-4"
                target="_blank"
              >
                <span class="fs-18 fw-500 c-light-black">#{{ subsidy.group_external_id }}</span>
              </router-link>
            </div>

            <div
              v-if="familySubsidyProgramLabels.length > 0"
              class="ms-3"
            >
              <LabelValue
                @edit="$refs.familySubsidyLabelDialog.open(subsidy)"
                :applied-labels="subsidy.relationships.labels.data"
                :disabled="processing"
              />
            </div>

            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-chip
                  @click="$refs.updateAwardAmountDialog.open(subsidy)"
                  v-bind="props"
                  :color="subsidy.award_amount ? 'green' : null"
                  class="ms-3"
                  size="small"
                >
                  <span v-if="subsidy.award_amount">{{ currency(subsidy.award_amount) }}</span>
                  <span v-else>$</span>
                </v-chip>
              </template>
              <span>{{ $t(`${terms.family_subsidy} award amount`) }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn
                  @click="toggleSubsidyLock"
                  v-bind="props"
                  :disabled="readonly"
                  class="ms-3"
                  color="primary"
                  data-cy="lock-button"
                  size="small"
                  variant="text"
                  icon
                >
                  <v-icon>{{ subsidy.locked ? 'lock' : 'lock_open' }}</v-icon>
                </v-btn>
              </template>
              <span v-if="subsidy.locked">{{
                $t('The application is locked. The applicant cannot make changes.')
              }}</span>
              <span v-else>{{
                $t('The application is unlocked. The applicant can make changes.')
              }}</span>
            </v-tooltip>

            <SubsidyEligibility
              :elevation="0"
              :funding-sources="subsidy.eligibility_determinations"
              :name="subsidy.group_name"
              :projected-eligibility="subsidy.projected_eligibility"
              explanation
              outlined
            />
          </div>

          <div class="d-flex align-center justify-end">
            <v-btn-toggle
              v-model="rightBarTab"
              class="me-3"
              selected-class="bg-primary c-white"
              variant="outlined"
              divided
              tile
            >
              <v-btn
                v-if="!familySubsidyProgram.enable_stages || currentStage.enable_reviews"
                aria-label="Reviews"
                value="reviews"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'reviews' ? 'white' : 'black'"
                  class="material-icons-outlined"
                  icon="reviews"
                />
              </v-btn>
              <v-btn
                aria-label="Messaging"
                value="messaging"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'messaging' ? 'white' : 'black'"
                  class="material-icons-outlined"
                  icon="forum"
                />
              </v-btn>
              <v-btn
                aria-label="Notes"
                value="notes"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'notes' ? 'white' : 'black'"
                  class="material-icons-outlined"
                  icon="assignment"
                />
              </v-btn>
            </v-btn-toggle>

            <template
              v-if="familySubsidyProgram.enable_stages && familySubsidyProgram.stages.length > 0"
            >
              <v-menu
                v-model="openStageDialog"
                :min-width="350"
                max-width="500"
                offset="5"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    append-icon="keyboard_arrow_down"
                    color="primary"
                    data-cy="stage-selector"
                    size="large"
                    variant="outlined"
                    tile
                  >
                    {{ $t(currentStage.title) }}
                  </v-btn>
                </template>

                <v-list
                  v-model:selected="currentStageId"
                  class="bc-light-gray b-1 py-0"
                  tile
                >
                  <template
                    v-for="(stage, index) in familySubsidyProgram.stages"
                    :key="stage.id"
                  >
                    <v-list-item
                      :class="
                        subsidy.stage_id === stage.id || currentStageIndex === index
                          ? 'bg-primary-extra-light'
                          : ''
                      "
                      lines="two"
                    >
                      <v-list-item-title>{{ $t(stage.title) }}</v-list-item-title>

                      <template #append>
                        <template v-if="index < currentStageIndex">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-btn
                              @click="setStage(index)"
                              v-bind="props"
                              :loading="processing"
                              :variant="isHovering ? 'outlined' : 'text'"
                              class="ms-3"
                              color="primary"
                              data-cy="set-stage-button"
                              icon="check"
                            />
                          </v-hover>
                        </template>
                        <template v-else-if="showCompleteButton(stage, index)">
                          <v-btn
                            @click="completeStage()"
                            :loading="processing"
                            class="ms-3"
                            color="primary"
                            data-cy="complete-stage-button"
                          >
                            {{ $t('Complete') }}
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-hover v-slot="{ isHovering, props }">
                            <v-btn
                              @click="setStage(index)"
                              v-bind="props"
                              :loading="processing"
                              class="ms-3"
                              color="grey-lighten-3"
                              data-cy="set-stage-button"
                              variant="outlined"
                              icon
                            >
                              <v-icon
                                v-if="isHovering"
                                color="primary-lighten-2"
                                icon="check"
                              />
                            </v-btn>
                          </v-hover>
                        </template>
                      </template>
                    </v-list-item>
                    <v-divider
                      v-if="index < familySubsidyProgram.stages.length - 1"
                      :key="index"
                    />
                  </template>
                </v-list>
              </v-menu>
            </template>

            <template v-if="$store.state.profile.org_family_subsidies_delete">
              <ActionMenu
                @click:action:delete="destroy"
                @click:action:download="download"
                :items="[
                  { event: 'delete', avatar: 'delete', title: 'Delete' },
                  { event: 'download', avatar: 'download', title: 'Download' },
                ]"
                button-class="ms-3"
                button-icon="more_vert"
                x-small
              />
            </template>
          </div>
        </div>

        <template #extension>
          <v-tabs>
            <template v-if="tabs.includes('Application')">
              <v-tab
                :to="{ query: { tab: 'Application' } }"
                class="c-black fs-16 ls-normal tt-none"
                data-cy="tab-application"
                exact
              >
                {{ $t('Application') }}
              </v-tab>
            </template>

            <template v-if="tabs.includes('Claims')">
              <v-tab
                :to="{ query: { tab: 'Claims' } }"
                class="c-black fs-16 ls-normal tt-none"
                data-cy="tab-claims"
                exact
              >
                {{ $t('Claims') }}
              </v-tab>
            </template>

            <template v-if="showHouseholdTab">
              <v-tab
                :to="{ query: { tab: 'Household' } }"
                class="c-black fs-16 ls-normal tt-none"
                data-cy="tab-family"
                exact
              >
                {{ $t('Household') }}
              </v-tab>
            </template>
          </v-tabs>
        </template>
      </v-toolbar>

      <div class="bg-super-light-blue fill-height d-flex">
        <div
          :style="rightBarTab ? 'width: 65%;' : 'width: 100%'"
          class="fill-height px-3"
          style="overflow-y: auto; padding-top: 130px"
        >
          <v-window v-model="tab">
            <v-window-item
              class="bg-transparent"
              value="Application"
            >
              <v-card
                border
                flat
                tile
              >
                <v-card-text>
                  <QuestionSet
                    v-model="subsidy"
                    @change="inputEventHandler"
                    @change:attachments="loadAttachments()"
                    @change:input="inputEventHandler"
                    :attachment-group-id="subsidy.group_id"
                    :attachment-owner-id="subsidy.id"
                    :attachments="attachments"
                    :color="null"
                    :elevation="0"
                    :model-value="subsidy"
                    :processing="processing"
                    :questions="validEligibilityQuestions"
                    :readonly="readonly"
                    :schema="schema.definition"
                    attachment-owner-type="FamilySubsidy"
                    condensed
                    dense
                    divided
                    expanded
                    hide-actions
                    tile
                  />

                  <QuestionSet
                    v-model="subsidy"
                    @change="inputEventHandler"
                    @change:attachments="loadAttachments()"
                    @change:input="inputEventHandler"
                    :attachment-group-id="subsidy.group_id"
                    :attachment-owner-id="subsidy.id"
                    :attachments="attachments"
                    :color="null"
                    :elevation="0"
                    :processing="processing"
                    :questions="validOtherQuestions"
                    :readonly="readonly"
                    :schema="schema.definition"
                    attachment-owner-type="FamilySubsidy"
                    condensed
                    dense
                    divided
                    expanded
                    hide-actions
                    tile
                  />

                  <div
                    v-for="question in validVerificationQuestions"
                    :key="question.id"
                    class="mb-6"
                  >
                    <FormQuestion
                      :color="null"
                      :elevation="0"
                      :subtitle="question.verification_subtitle"
                      :title="question.verification_title"
                      condensed
                      dense
                      divided
                      hide-actions
                      tile
                    >
                      <AttachmentUploader
                        @uploaded="attachments.push($event)"
                        :ref="['uploader', question.id].join('')"
                        :owner="{
                          group_id: subsidy.group_id,
                          type: 'FamilySubsidyProgram',
                          id: familySubsidyProgram.id,
                          tag: question.id,
                          tags: [subsidy.id, question.id],
                          description: question.attachment_description || question.title,
                        }"
                        class="mb-4"
                      />

                      <AttachmentList
                        @change="loadAttachments()"
                        @delete="loadAttachments"
                        :attachments="
                          attachments.filter((attachment) => attachment.tag == question.id)
                        "
                        class="mb-6"
                      />
                    </FormQuestion>
                  </div>
                </v-card-text>
              </v-card>

              <SubmittedSubsidyMemo
                :border="false"
                :created-at="subsidy.created_at"
                :owner-id="subsidy.id"
                :owner-type="'FamilySubsidy'"
                :submitted-at="subsidy.submitted_at"
              />

              <template v-if="!subsidy.submitted_at">
                <v-row class="mt-3">
                  <v-col>
                    <v-btn
                      @click="$refs.submitDialog.open()"
                      :disabled="changed || !subsidy.projected_eligibility"
                      :loading="processing"
                      color="primary"
                      block
                    >
                      {{ $t('Submit') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-window-item>

            <v-window-item
              v-if="familySubsidyProgram.enable_claims"
              class="bg-transparent"
              value="Claims"
            >
              <ClaimsTable
                :family-subsidy="subsidy"
                :readonly="readonly"
              />
            </v-window-item>
            <v-window-item
              class="bg-transparent"
              value="Household"
            >
              <v-card
                border
                flat
                tile
              >
                <v-card-text>
                  <div v-if="householdMembers.length === 0">
                    {{ $t('No household members to show.') }}
                  </div>
                  <HouseholdMembersCard
                    :can-edit="canEditHouseholdMembers"
                    :household-member-api="getHouseholdMemberApi()"
                    :household-member-question-register="householdMemberQuestionRegister"
                    :household-member-schema="householdMemberSchema"
                    :is-expandable="true"
                    :subsidy="subsidy"
                    :value="householdMembers"
                  />
                </v-card-text>
              </v-card>
            </v-window-item>
          </v-window>
          <VerticalSpacer :min-height="60" />
        </div>

        <v-card
          :style="rightBarTab ? 'width: 35%;' : 'display: none;'"
          class="fill-height"
          style="overflow-y: auto; padding-top: 118px"
          border
        >
          <v-window
            v-model="rightBarTab"
            :mandatory="false"
            eager-load
          >
            <v-window-item
              key="messaging"
              value="messaging"
            >
              <SubsidyMessagesLayout @close="rightBarTab = null">
                <SubsidyMessages
                  :message-templates="familySubsidyProgram.message_templates"
                  :subsidy="subsidy"
                />
              </SubsidyMessagesLayout>
            </v-window-item>

            <v-window-item
              key="notes"
              value="notes"
            >
              <SubsidyNotes
                @close="rightBarTab = null"
                :subsidy="subsidy"
                owner-type="FamilySubsidy"
              />
            </v-window-item>

            <template v-if="!familySubsidyProgram.enable_stages || currentStage.enable_reviews">
              <v-window-item
                key="reviews"
                value="reviews"
              >
                <ReviewsList
                  @close="rightBarTab = null"
                  @published="load()"
                  :associated-schema-ids="familySubsidyProgram.associated_schema_ids"
                  :owner="subsidy"
                  :owner-type="'FamilySubsidy'"
                  :statuses="familySubsidyProgram.subsidy_statuses"
                />
              </v-window-item>
            </template>
          </v-window>
        </v-card>
      </div>

      <ResourceDialog
        @save="submit"
        ref="submitDialog"
        :max-width="800"
        :processing="processing"
        title="Submit application"
      >
        <template #form="{ localValue }">
          <div class="b-1 bc-very-light-grey b-radius-4 pa-md-6 pa-3 mb-4">
            <LabeledDatePicker
              v-model="localValue.submitted_at"
              :disallow-after-date="new Date().toISOString()"
              message="Submission date"
              placeholder-text="Today"
            />
          </div>

          <UserAttestation
            @change="localValue.attestation = $event"
            ref="adminAttestation"
            :attestation-text="familySubsidyProgram.admin_attestation_text"
            enable-esign
          />
        </template>
      </ResourceDialog>

      <LabelDialog
        @change="updateFamilySubsidyLabels($event)"
        ref="familySubsidyLabelDialog"
        :program-labels="familySubsidyProgramLabels"
        label-owner-type="FamilySubsidy"
      />
    </template>

    <template v-else>
      <v-progress-linear indeterminate />
    </template>

    <ResourceDialog
      @save="setSubsidyAwardAmount"
      ref="updateAwardAmountDialog"
      :fields="[{ text: 'Award amount', value: 'award_amount', type: 'currency', required: true }]"
      :processing="processing"
    >
      <template #append>
        <v-alert
          v-if="invalidAwardAmount"
          type="error"
        >
          <div>
            {{ $t('Award amount must be greater than zero.') }}
          </div>
        </v-alert>
      </template>
    </ResourceDialog>

    <SaveBar
      @cancel="reset"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import ClaimsTable from '@/specialist/components/claims/ClaimsTable.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import HouseholdMembersCard from '@/shared/components/household-members/HouseholdMembersCard.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import ReviewsList from '@/specialist/components/ReviewsList.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SubmittedSubsidyMemo from '@/specialist/components/subsidy/SubmittedSubsidyMemo.vue';
import SubsidyEligibility from '@/specialist/components/subsidy/SubsidyEligibility.vue';
import SubsidyMessages from '@/specialist/components/subsidy/SubsidyMessages.vue';
import SubsidyMessagesLayout from '@/specialist/components/subsidy/SubsidyMessagesLayout.vue';
import SubsidyNotes from '@/specialist/components/subsidy/SubsidyNotes.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useMyFamilySubsidyUtils from '@/shared/composables/useMyFamilySubsidyUtils';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import { getTabsForFamilySubsidyStage } from '@/specialist/services/subsidy_review';
import householdMemberApi from '@/specialist/services/api/household-member';
import { currency } from '@/plugins/filters';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const { updateQuery } = useRouterHelper();
const { terms } = useTerms();
const store = useStore();
const eventBus = useEventBus();

const adminAttestation = ref(null);
const attachments = ref([]);
const changed = ref(false);
const createRevision = ref(null);
const currentStageId = ref(null);
const familySubsidyProgram = ref(null);
const familySubsidyProgramLabels = ref([]);
const householdMembers = ref([]);
const householdMemberSchema = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'HouseholdMember',
)[0];
const invalidAwardAmount = ref(false);
const loading = ref(true);
const openStageDialog = ref(false);
const processing = ref(false);
const rightBarTab = ref(null);
const rightBarVisible = ref(false);
const route = useRoute();
const router = useRouter();
const schema = ref(null);
const submitRevision = ref(null);
const submitDialog = ref(null);
const subsidy = ref(null);
const tab = ref(null);
const updateAwardAmountDialog = ref(null);

const {
  householdMemberQuestionRegister,
  loadQuestions,
  validate,
  validEligibilityQuestions,
  validOtherQuestions,
  validVerificationQuestions,
} = useMyFamilySubsidyUtils({
  familySubsidyProgram,
  householdMembers,
  subsidy,
});

const canEditHouseholdMembers = computed(() => {
  return !!(
    (store.state.profile.org_family_subsidies_access ||
      store.state.profile.org_family_subsidies_admin) &&
    store.state.profile.org_family_subsidies_edit
  );
});

const canViewHouseholdMembers = computed(() => {
  return (
    store.state.profile.org_family_subsidies_access ||
    store.state.profile.org_family_subsidies_admin
  );
});

const currentStage = computed(() => {
  return familySubsidyProgram.value?.stages[currentStageIndex.value] || {};
});

const currentStageIndex = computed(() => {
  if (familySubsidyProgram.value && subsidy.value) {
    const index = familySubsidyProgram.value.stages.findIndex(
      (stage) => subsidy.value.stage_id === stage.id,
    );
    return !index || index === -1 ? 0 : index;
  }
  return 0;
});

const isDataLoaded = computed(() => {
  return schema.value && subsidy.value && familySubsidyProgram.value;
});

const readonly = computed(() => {
  return (
    !store.state.profile.org_family_subsidies_edit ||
    (!store.state.profile.org_family_subsidies_admin && subsidy.value?.locked)
  );
});

const showHouseholdTab = computed(() => {
  return (
    tabs.value.includes('Household') &&
    familySubsidyProgram.value.enable_household_members &&
    canViewHouseholdMembers.value
  );
});

const tabs = computed(() => {
  return getTabsForFamilySubsidyStage(
    familySubsidyProgram.value,
    subsidy.value,
    currentStage.value,
  );
});

watch(
  () => rightBarTab.value,
  (newVal) => {
    if (newVal != null) {
      rightBarVisible.value = true;
    } else {
      rightBarVisible.value = false;
    }
  },
);

watch(
  () => route.params.id,
  async (newVal) => {
    if (newVal) {
      loading.value = true;
      await load();
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => route.query.tab,
  (newVal) => {
    if (newVal) {
      tab.value = newVal;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => tabs.value,
  async (newVal) => {
    if (newVal.length > 0 && !tab.value) {
      tab.value = newVal[0];
      updateQuery({ tab: tab.value });
    }
  },
  {
    deep: true,
  },
);

async function completeStage() {
  subsidy.value.stage_id = familySubsidyProgram.value.stages[currentStageIndex.value + 1].id;
  await save();
}

function destroy() {
  if (!confirm('Are you sure you want to delete this application? This cannot be undone.')) return;

  Api.organization.family_subsidy.destroy(subsidy.value);
  eventBus.chime('Deleted successfully');
  router.replace({ name: 'FamilySubsidyIndex' });
}

function download() {
  Api.organization.family_subsidy.download(subsidy.value.id);
}

function getHouseholdMemberApi() {
  return householdMemberApi;
}

function inputEventHandler() {
  changed.value = true;
}

async function load() {
  await loadFamilySubsidy();
  await loadFamilySubsidyProgram();
  await loadSchema();
  await loadQuestions();
  await loadRevisions();
  await loadAttachments();
}

async function loadAttachments() {
  const params = {
    group_id: subsidy.value.group_id,
    owner_id: [subsidy.value.family_subsidy_program_id, subsidy.value.id],
    owner_type: ['FamilySubsidyProgram', 'FamilySubsidy'],
  };

  const resp = await Api.organization.attachment.index(params);
  if (resp?.status !== 200) return;
  attachments.value = resp.data;
}

async function loadFamilySubsidy() {
  const resp = await Api.organization.family_subsidy.get(route.params.id);
  subsidy.value = resp.data;
  householdMemberApi.setFamilySubsidyId(subsidy.value.id);
  currentStageId.value = subsidy.value.stage_id;
  householdMembers.value = subsidy.value.relationships.household_members?.data || [];
}

async function loadFamilySubsidyProgram() {
  const resp = await Api.organization.family_subsidy_program.get(
    subsidy.value.family_subsidy_program_id,
  );
  familySubsidyProgram.value = resp.data;
  await loadFamilySubsidyProgramLabels();
}

async function loadFamilySubsidyProgramLabels() {
  const resp = await Api.organization.label.index({
    family_subsidy_program_id: familySubsidyProgram.value.id,
  });
  familySubsidyProgramLabels.value = resp.data;
}

async function loadRevisions() {
  const resp = await Api.organization.revision.index({
    owner_id: subsidy.value.id,
    owner_type: 'FamilySubsidy',
  });
  createRevision.value = resp.data[resp.data.length - 1];
  submitRevision.value = resp.data.filter(
    (revision) => revision.data.submitted_at && !revision.data.submitted_at[0],
  )[0];
}

async function loadSchema() {
  const resp = await Api.organization.schema.get(
    familySubsidyProgram.value.family_subsidy_schema_id,
  );
  schema.value = resp.data;
}

function setSubsidyAwardAmount(params) {
  if (params.award_amount <= 0) {
    invalidAwardAmount.value = true;
    return false;
  }

  invalidAwardAmount.value = false;
  processing.value = true;

  const resp = Api.organization.family_subsidy
    .update(params)
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (!resp?.data) return false;

  subsidy.value = resp.data;
  return updateAwardAmountDialog.value.close();
}

async function reset() {
  changed.value = false;
  await load();
}

async function save() {
  processing.value = true;
  const resp = await Api.organization.family_subsidy
    .update(subsidy.value)
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (!resp?.data) return;

  subsidy.value = resp.data;
  eventBus.chime('Application saved');
  changed.value = false;
  validate();
}

async function submit(submission) {
  if (!submission.attestation) {
    eventBus.chime('Adopt a signature to submit');
    return;
  }

  const params = {
    revision_author_signed_name: adminAttestation.value.esignName,
    revision_author_signature_image: adminAttestation.value.getEsignSignature(),
    revision_description: adminAttestation.value.getAttestationText(),
    submitted_at: submission.submitted_at,
  };

  processing.value = true;
  const resp = await Api.organization.family_subsidy
    .submit(subsidy.value.id, params)
    .catch((error) => eventBus.error(error));
  submitDialog.value.close();
  processing.value = false;
  if (!resp?.data) return;

  await load();
}

async function setStage(index) {
  if (index < currentStageIndex.value) {
    if (confirm('Are you sure you want to move back to a previous stage?')) {
      subsidy.value.stage_id = familySubsidyProgram.value.stages[index].id;
      await save();
    }
  } else if (index === currentStageIndex.value + 1) {
    // Selecting next stage is logically equivalent to completing current stage
    await completeStage();
  } else if (confirm('Are you sure you want to skip stages?')) {
    subsidy.value.stage_id = familySubsidyProgram.value.stages[index].id;
    await save();
  }
}

function showCompleteButton(stage, index) {
  return (
    currentStageIndex.value < familySubsidyProgram.value.stages.length - 1 &&
    (subsidy.value.stage_id === stage.id || currentStageIndex.value === index)
  );
}

async function toggleSubsidyLock() {
  await update(
    {
      id: subsidy.value.id,
      locked: !subsidy.value.locked,
    },
    subsidy.value.locked ? 'Unlocked' : 'Locked',
  );
}

async function update(newVal, msg) {
  processing.value = true;
  const resp = await Api.organization.family_subsidy
    .update(newVal)
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (!resp?.data) return;

  subsidy.value = resp.data;
  if (msg) eventBus.chime(msg);
}

function updateFamilySubsidyLabels(updatedSubsidy) {
  subsidy.value.relationships.labels.data = updatedSubsidy.relationships.labels.data;
}
</script>
