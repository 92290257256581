<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="createNewSpecialistForm"
      :createable="displayNewFormButton"
      :title="terms.forms || 'Forms'"
      create-text="New form"
    />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      extension-height="60"
    >
      <v-row class="d-flex align-center">
        <template v-if="schemas.length > 0">
          <v-col class="mxw-400">
            <v-select
              v-model="selectedSchema"
              @update:model-value="handleSchemaChange"
              :aria-label="selectedSchema?.name"
              :items="schemas"
              data-cy="schemas_list"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </template>

        <v-spacer />

        <v-btn
          v-show="showAssignButtons"
          @click="handleSelectedItemTeamEdit"
          class="me-3"
          color="primary"
          variant="outlined"
        >
          {{ $t('Assign team') }}
        </v-btn>

        <v-btn
          v-show="showAssignButtons"
          @click="handleSelectedItemReviewerEdit"
          class="me-3"
          color="primary"
          variant="outlined"
        >
          {{ $t('Assign member') }}
        </v-btn>
      </v-row>

      <template
        v-if="selectedSchema"
        #extension
      >
        <div class="d-flex flex-grow-1 align-center">
          <FilterMenu
            :active="selectedStatuses.length > 0"
            classes="d-md-inline-flex"
            test-id="status-filter-button"
            title="Status"
          >
            <template #card>
              <v-autocomplete
                v-model="selectedStatuses"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by status')"
                :disabled="processing"
                :item-title="(item) => $t(item)"
                :items="statuses"
                :menu="true"
                :placeholder="$t('Filter by status')"
                density="compact"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="teams.length > 0"
            :active="selectedTeams.length > 0"
            classes="d-md-inline-flex"
            test-id="team-filter-button"
            title="Team"
          >
            <template #card>
              <v-autocomplete
                v-model="selectedTeams"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by team name')"
                :disabled="processing"
                :item-title="(item) => $t(item.name)"
                :items="teams"
                :menu="true"
                :placeholder="$t('Filter by team name')"
                density="compact"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="specialists.length > 0"
            :active="selectedReviewers.length > 0"
            classes="d-md-inline-flex"
            title="Assignment"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="selectedReviewers"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by reviewer name')"
                :disabled="processing"
                :items="specialists"
                :menu="true"
                :placeholder="$t('Filter by reviewer name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="selectedSchema.enable_stages"
            :active="selectedStages.length > 0"
            classes="d-md-inline-flex"
            title="Stage"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="selectedStages"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by stage title')"
                :item-title="(item) => $t(item.title)"
                :items="stages"
                :menu="true"
                :placeholder="$t('Filter by stage title')"
                density="compact"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="displayLabels"
            :active="selectedLabels.length > 0"
            classes="d-md-inline-flex"
            title="Label"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="selectedLabels"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by label name')"
                :disabled="processing"
                :items="labels"
                :menu="true"
                :placeholder="$t('Filter by label name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              >
                <template #chip="{ item, props }">
                  <v-chip v-bind="props">
                    <template #prepend>
                      <v-avatar
                        :color="item.raw.color"
                        start
                      />
                    </template>
                    {{ item.raw.name }}
                  </v-chip>
                </template>
                <template #item="{ item, props }">
                  <v-list-item
                    v-bind="props"
                    :title="$t(item.raw.name)"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :key="item.value"
                        :model-value="isSelected"
                        :ripple="false"
                        tabindex="-1"
                      />
                    </template>
                    <template #append>
                      <v-avatar
                        :color="item.raw.color"
                        size="20"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </FilterMenu>

          <template
            v-if="
              selectedCustomView?.attributes.columns.length > 0 ||
              selectedCustomView?.attributes.filters.length > 0
            "
          >
            <v-btn
              @click="handleCustomViewFiltersEdit"
              :class="filtersButtonClass"
              variant="outlined"
              rounded
            >
              <span v-t="'More filters'" />
              <v-icon
                class="ms-3"
                icon="filter_list"
                size="18"
              />
            </v-btn>
          </template>

          <v-spacer />

          <div class="ms-3 mxw-250">
            <v-select
              v-model="selectedCustomView"
              @update:model-value="handleSchemaChange"
              :aria-label="selectedSchema?.name"
              :items="[selectedCustomView]"
              data-cy="custom_views_list"
              density="compact"
              item-title="attributes.name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </div>

          <v-btn
            @click="customViewManageEditor.open()"
            :class="columnsButtonClass"
            class="ms-2"
            prepend-icon="tune"
            variant="outlined"
          >
            {{ $t('Manage views') }}
          </v-btn>
        </div>
      </template>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      @update:model-value="handleModelValueUpdate"
      @update:page="handlePageChange"
      @update:sort-by="handleCustomViewSortsUpdate"
      :headers="headers"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="100"
      :loading="processing"
      :model-value="selectedItems"
      :page="currentPage"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No forms found"
      fixed-header
      hover
      return-object
      show-select
    >
      <template #item.id="{ item }">
        <span>
          {{ item.id.split('-')[0] }}
        </span>
      </template>

      <template #item.submitter="{ item }">
        <span>
          {{ getFormSubmitter(item) }}
        </span>
      </template>

      <template #item.team="{ item }">
        <v-tooltip
          v-if="getFormTeamName(item)"
          location="bottom"
          center
        >
          <template #activator="{ props }">
            <v-chip
              @click.stop.prevent
              @click:close="removeReviewer(item, true)"
              v-bind="props"
              :closable="store.state.profile.org_providers_admin"
              :disabled="processing"
              close-icon="close"
              size="small"
            >
              <InitialedText
                @click.stop.prevent
                :value="getFormTeamName(item)"
              />
            </v-chip>
          </template>

          <span>
            {{ getFormTeamName(item) }}
          </span>
        </v-tooltip>
      </template>

      <template #item.assigned="{ item }">
        <v-tooltip
          v-if="getFormReviewerName(item)"
          location="bottom"
          center
        >
          <template #activator="{ props }">
            <v-chip
              @click.stop.prevent
              @click:close="removeReviewer(item, false)"
              v-bind="props"
              :closable="store.state.profile.org_providers_admin"
              :disabled="processing"
              close-icon="close"
              size="small"
            >
              <InitialedText
                @click.stop.prevent
                :value="getFormReviewerName(item)"
              />
            </v-chip>
          </template>

          <span>
            {{ getFormReviewerName(item) }}
          </span>
        </v-tooltip>
      </template>

      <template #item.submitted_at="{ item }">
        <LongDate
          :date="item.submitted_at"
          short-month
        />
      </template>

      <template #item.status="{ item }">
        {{ $t(item.status) }}
      </template>

      <template #item.enrollment="{ item }">
        <router-link
          v-if="formHasEnrollmentAndSubsidy(item)"
          @click.stop.prevent
          :ref="`subsidyLink-${item.subsidy_id}`"
          :to="{
            name: 'SubsidyShow',
            params: {
              id: item.subsidy_id,
            },
            query: {
              tab: 'Enrollment',
            },
          }"
          tracked
        >
          {{ $t(getFormEnrollmentName(item)) }}
        </router-link>

        <span v-else>
          {{ $t(getFormEnrollmentName(item)) }}
        </span>
      </template>

      <template #item.provider="{ item }">
        <router-link
          v-if="item.provider_id"
          @click.stop.prevent
          :ref="`providerLink-${item.provider_id}`"
          :to="{
            name: 'ProviderShow',
            params: {
              providerId: item.provider_id,
            },
            query: {
              tab: 'forms',
            },
          }"
          tracked
        >
          {{ $t(getFormProviderName(item)) }}
        </router-link>

        <span v-else>
          {{ $t(getFormProviderName(item)) }}
        </span>
      </template>

      <template #item.stage="{ item }">
        <template v-if="stages">
          {{ $t(getFormStageName(item)) }}
        </template>
      </template>

      <template #item.labels="{ item }">
        <LabelValue
          @edit="labelDialog.open(item)"
          :applied-labels="getFormLabels(item)"
          :disabled="processing"
        />
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <LabelDialog
      @change="updateEditedLabels"
      @close="loadResults()"
      ref="labelDialog"
      :program-labels="labels"
      label-owner-type="Form"
    />

    <CustomViewFiltersEditor
      @save="handleCustomViewFiltersUpdate"
      ref="customViewFiltersEditor"
      :columns="selectedCustomView?.attributes.columns"
      :processing="processing"
    />

    <CustomViewManageEditor
      @reload="loadCustomViews"
      @save="handleCustomViewUpdate"
      ref="customViewManageEditor"
      :allowed-properties="allowedProperties"
      :custom-views="customViews"
    />

    <TeamEditor
      @save="handleSelectedItemTeamUpdate"
      ref="selectedItemTeamEditor"
      :processing="processing"
      :teams="teams"
    />

    <ReviewerEditor
      @save="handleSelectedItemReviewerUpdate"
      ref="selectedItemReviewerEditor"
      :processing="processing"
      :reviewers="specialists"
    />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import CustomViewFiltersEditor from '@/specialist/components/custom-views/CustomViewFiltersEditor.vue';
import CustomViewManageEditor from '@/specialist/components/custom-views/CustomViewManageEditor.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import InitialedText from '@/shared/components/InitialedText.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ReviewerEditor from '@/specialist/components/ReviewerEditor.vue';
import TeamEditor from '@/specialist/components/TeamEditor.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import {
  STATIC_STATUSES,
  NEUTRAL_STATUSES,
  POSITIVE_STATUSES,
} from '@/specialist/services/statuses';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';

const route = useRoute();
const router = useRouter();
const store = useStore();
const { terms } = useTerms();
const { updateQuery } = useRouterHelper();

const allowedProperties = ref([]);
const customViewFiltersEditor = ref(null);
const customViewManageEditor = ref(null);
const customViews = ref([]);
const enrollments = ref([]);
const headers = ref([]);
const items = ref([]);
const labelDialog = ref(null);
const members = ref([]);
const page = ref(route.query.page || 1);
const processing = ref(false);
const providers = ref([]);
const selectedCustomView = ref(null);
const selectedItemReviewerEditor = ref(null);
const selectedItemTeamEditor = ref(null);
const selectedItems = ref([]);
const selectedLabels = ref([]);
const selectedReviewers = ref([]);
const selectedSchema = ref(null);
const selectedStages = ref([]);
const selectedStatuses = ref([]);
const selectedTeams = ref([]);
const schemas = ref([]);
const specialists = ref([]);
const statuses = ref(STATIC_STATUSES.concat(NEUTRAL_STATUSES, POSITIVE_STATUSES));
const labels = ref([]);
const teams = ref([]);
const totalItemCount = ref(null);
const workflowColumns = ref([]);

watch(customViews, (newValue) => {
  if (!newValue[0]) return;

  [selectedCustomView.value] = newValue;
  void handleCustomViewChange(selectedCustomView.value);
});

const columnsButtonClass = computed(() => {
  return getHighlightedClass(selectedCustomView.value?.attributes.columns.length);
});

const currentPage = computed(() => (route.query.page ? Number(route.query.page) : 1));

const displayNewFormButton = computed(() => {
  const authorizedToCreate =
    store.state.profile.org_forms_admin || store.state.profile.org_forms_create;

  return authorizedToCreate && selectedSchema.value?.meta.specialist_requestable;
});

const filtersButtonClass = computed(() => {
  return getHighlightedClass(selectedCustomView.value?.attributes.filters.length);
});

const selectedSchemaId = computed(() => route.query.schemaId || null);

const showAssignButtons = computed(() => {
  const authorizedToAssign =
    store.state.profile.org_forms_admin || store.state.profile.org_forms_edit;

  return authorizedToAssign && selectedItems.value.length > 0;
});

const stages = computed(() => {
  return selectedSchema?.value.stages || [];
});

async function addPageParams(page) {
  const params = {
    page,
    schemaId: selectedSchema.value.id,
  };

  if (currentPage.value !== page) await updateQuery(params);
}

async function createNewSpecialistForm() {
  const response = await Api.organization.form.create({
    schema_id: selectedSchema.value.id,
  });

  if (response?.status != 201) return;

  router.push({
    name: store.state.profile.org_forms_review ? 'FormReview' : 'FormShow',
    params: {
      formId: response?.data.id,
    },
  });
}

// Enrollment column functions
function formHasEnrollmentAndSubsidy(form) {
  const enrollmentId = form?.enrollment_id || null;
  const subsidyId = form?.subsidy_id || null;

  return enrollmentId && subsidyId;
}

function getHighlightedClass(length) {
  return length > 0 ? 'bc-primary-light bg-primary-extra-light' : 'bg-white bc-very-light-gray';
}

function getFiltersAsCustomViewParams() {
  return {
    label_ids: selectedLabels.value,
    reviewer_ids: selectedReviewers.value,
    stage_ids: selectedStages.value,
    statuses: selectedStatuses.value,
    team_ids: selectedTeams.value,
  };
}

function getFormEnrollmentName(form) {
  const enrollmentId = form?.enrollment_id || null;
  if (!enrollmentId) return '';

  const formEnrollment = enrollments.value.find((enrollment) => enrollment.id === enrollmentId);

  return formEnrollment?.name || '';
}

function getFormLabels(form) {
  const labelIds = form?.label_ids || [];
  if (labelIds.length === 0) return [];

  return labels.value?.filter((label) => labelIds.includes(label.id)) || [];
}

function getFormLink(formId) {
  return {
    name: store.state.profile.org_forms_review ? 'FormReview' : 'FormShow',
    params: { formId },
  };
}

function getFormProvider(form) {
  const providerId = form?.provider_id || null;
  if (!providerId) return '';

  const formProvider = providers.value.find((provider) => provider.id === providerId);

  return formProvider || {};
}

function getFormProviderName(form) {
  return getFormProvider(form)?.name || '';
}

// Reviewer column function
function getFormReviewerName(form) {
  const reviewerId = form?.reviewer_id || null;
  if (!reviewerId) return '';

  const formReviewer = specialists.value.find((reviewer) => reviewer.id === reviewerId);

  return formReviewer?.name || '';
}

// Stage column function
function getFormStageName(form) {
  const stageId = form?.stage_id || null;
  if (!stageId) return '';

  const formStage = stages.value.find((stage) => stage.id === stageId);

  return formStage?.title || '';
}

function getFormSubmitter(form) {
  const memberId = form?.member_id || null;
  if (!memberId || members.value.length === 0) return '';

  const submitter = members.value.find((member) => member.id === memberId);
  return submitter?.name || '';
}

// Team column function
function getFormTeamName(form) {
  const teamId = form?.team_id || null;
  if (!teamId) return '';

  const formTeam = teams.value.find((team) => team.id === teamId);

  return formTeam?.name || '';
}

function handleCustomViewFiltersEdit() {
  customViewFiltersEditor.value.open(selectedCustomView.value.attributes.filters);
}

async function handleCustomViewFiltersUpdate(newValue) {
  await handleCustomViewUpdate({ filters: newValue });
  customViewFiltersEditor.value.close();
}

async function handleCustomViewChange(newVal) {
  selectedLabels.value = newVal.attributes.label_ids;
  selectedReviewers.value = newVal.attributes.reviewer_ids;
  selectedStages.value = newVal.attributes.stage_ids;
  selectedStatuses.value = newVal.attributes.statuses;
  selectedTeams.value = newVal.attributes.team_ids;

  selectedCustomView.value = newVal;
  await loadSchemaLabels();
  await loadResults();
}

async function handleCustomViewUpdate(newValue) {
  processing.value = true;

  const updatedAttributes = { ...selectedCustomView.value?.attributes, ...newValue };
  const response = await Api.organization.formCustomView.update(
    selectedCustomView.value.id,
    updatedAttributes,
    selectedCustomView.value?.relationships,
  );

  if (response?.status !== 200) return false;

  selectedCustomView.value = response.data.data;
  const customViewToUpdate = customViews.value.find(
    (customView) => customView.id === selectedCustomView.value.id,
  );
  if (customViewToUpdate) customViewToUpdate.attributes = response.data.data.attributes;

  await loadResults();

  return true;
}

async function handleFilterChange() {
  await resetPage(1);
  await handleCustomViewUpdate(getFiltersAsCustomViewParams());
}

async function handlePageChange(newVal) {
  page.value = newVal;
  await addPageParams(newVal);
  await loadResults();
}

function handleRowClick(_event, { item }) {
  router.push(getFormLink(item.id));
}

async function handleSchemaChange() {
  processing.value = true;

  customViews.value = [];
  allowedProperties.value = [];
  await updateQuery({ schemaId: selectedSchema.value.id });

  await loadCustomViews();
  await loadSchemaLabels();
  await handlePageChange(1);
}

async function handleModelValueUpdate(event) {
  selectedItems.value = event;
}

async function handleCustomViewSortsUpdate(newVal) {
  const processedSorts = processSorts(newVal);
  await handleCustomViewUpdate(processedSorts);
}

function handleSelectedItemReviewerEdit() {
  selectedItemReviewerEditor.value.open();
}

async function handleSelectedItemReviewerUpdate(newValue) {
  processing.value = true;

  await Promise.all(
    selectedItems.value.map(async (selectedItem) => {
      const response = await Api.organization.form.update(selectedItem.id, {
        reviewer_id: newValue.value,
      });

      const index = items.value.findIndex((item) => item.id === selectedItem.id);
      items.value[index] = response.data;
    }),
  );

  selectedItems.value = [];
  selectedItemReviewerEditor.value.close();

  await loadResults();
}

function handleSelectedItemTeamEdit() {
  selectedItemTeamEditor.value.open();
}

async function handleSelectedItemTeamUpdate(newValue) {
  processing.value = true;

  await Promise.all(
    selectedItems.value.map(async (selectedItem) => {
      const response = await Api.organization.form.update(selectedItem.id, {
        team_id: newValue.value,
      });

      const index = items.value.findIndex((item) => item.id === selectedItem.id);
      items.value[index] = response.data;
    }),
  );

  selectedItems.value = [];
  selectedItemTeamEditor.value.close();

  await loadResults();
}

async function load() {
  items.value.splice(0);
  processing.value = true;

  let unorderedSchemas = Object.values(store.state.schemas).filter(
    (schema) => schema.data_type === 'Form',
  );

  await loadSpecialists();

  schemas.value = _.orderBy(unorderedSchemas, [(schema) => schema.name.toLowerCase()], ['asc']);
  selectedSchema.value =
    schemas.value.find((schema) => schema.id === selectedSchemaId.value) || schemas.value[0];

  await loadCustomViews();
  await loadSchemaLabels();
  await loadTeams();

  processing.value = false;
}

async function loadCustomViews() {
  const params = {
    'filter[schema]': selectedSchema.value.id,
  };
  const { data } = await Api.organization.formCustomView.index(params);
  customViews.value = data.data;
  allowedProperties.value = data.meta.allowed_values.columns;
  workflowColumns.value = data.meta.allowed_values.workflow_columns;
  selectedCustomView.value = data.data[0];
}

function loadEnrollments(id) {
  Api.organization.enrollment.index({ id }, (response) => (enrollments.value = response.data));
}

function loadMembers(ids) {
  Api.organization.member.index(
    { ids },
    (response) => (members.value = members.value.concat(response?.data || [])),
  );
}

function loadProviders(id) {
  Api.organization.provider.index({ id }, (response) => (providers.value = response.data));
}

async function loadResults() {
  processing.value = true;

  const { data } = await Api.organization.customViewResult.index({
    customViewId: selectedCustomView.value.id,
    page: currentPage.value,
  });

  totalItemCount.value = data.meta.total_count;

  headers.value = _.map(data.meta.all_columns, (column) => ({
    title: column.alias_name,
    key: column.key,
  }));

  items.value = _.map(data.data, (item) => ({
    ...item.attributes,
    ...item.relationships,
    id: item.id,
  }));

  const providerIds = items.value.map((form) => form.provider_id).filter(Boolean);
  loadProviders(providerIds);

  const enrollmentIds = items.value.map((form) => form.enrollment_id).filter(Boolean);
  loadEnrollments(enrollmentIds);

  // Load submitters and reviewers
  const memberIds = items.value
    .map((form) => [form.member_id, form.reviewer_id])
    .flat()
    .filter(Boolean)
    .filter((id) => !members.value.find((member) => member.id === id));

  if (memberIds.length > 0) loadMembers(memberIds);

  processing.value = false;
}

async function loadSchemaLabels() {
  if (!store.state.profile.org_settings_access) return;

  const response = await Api.organization.label.index({
    schema_id: selectedSchema.value?.id,
  });

  labels.value = response?.data || [];
}

async function loadSpecialists() {
  const response = await Api.organization.member.index({ is_specialist: true });
  specialists.value = response?.data || [];
  members.value = members.value.concat(response?.data || []);
}

async function loadTeams() {
  const response = await Api.organization.team.index();
  teams.value = response?.data || [];
}

function processSorts(newVal) {
  // when newVal returns an empty array, it means the user has removed all sorts
  if (newVal.length < 1) return { sorts: [] };

  const incomingSort = newVal[0];
  let existingColumns = selectedCustomView.value.attributes.columns;
  let existingSorts = selectedCustomView.value.attributes.sorts;
  let incomingDirection = `${incomingSort.order}ending`;

  const columnValue =
    existingColumns.find((column) => column.key === incomingSort.key) ||
    workflowColumns.value.find((column) => column.key === incomingSort.key);

  const sortValue = existingSorts.find(
    (sort) =>
      sort.data_type === columnValue.data_type && sort.property_name === columnValue.property_name,
  );

  if (sortValue) {
    sortValue.direction = incomingDirection;
    existingSorts = [sortValue];
  } else {
    const key = uuid().replaceAll('-', '');
    const newSort = {
      key,
      direction: incomingDirection,
      data_type: columnValue.data_type,
      property_name: columnValue.property_name,
    };
    existingSorts = [newSort];
  }

  return { sorts: existingSorts };
}

async function removeReviewer(form, isTeam) {
  processing.value = true;
  const params = {
    team_id: isTeam ? null : form.team_id,
    reviewer_id: isTeam ? form.reviewer_id : null,
  };

  await Api.organization.form.update(form.id, params);
  await loadResults();
}

async function resetPage() {
  page.value = 1;
  await addPageParams(1);
}

function updateEditedLabels(form) {
  const index = items.value.findIndex((item) => item.id === form.id);
  items.value[index] = form;
}

onMounted(load);
</script>
