<template>
  <div class="fill-height">
    <template v-if="!loaded">
      <v-progress-linear indeterminate />
    </template>
    <template v-else>
      <v-toolbar
        class="bc-outlined-gray bb-1"
        elevation="0"
        height="76"
        style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
        absolute
      >
        <div class="d-flex flex-1-0 align-center px-4">
          <div class="d-flex flex-column flex-1-0">
            <v-row
              class="fs-14 c-light-black fw-500"
              dense
            >
              <v-col>
                <span>
                  <router-link
                    :to="{ name: 'SubsidyIndex', query: backToIndexWithSubsidyProgram }"
                    class="hover-underlined c-light-black fw-500"
                    data-testid="subsidy-show-breadcrumb"
                  >
                    {{ subsidyProgram?.name }}
                  </router-link>
                </span>
                <span class="px-3">/</span>
                <router-link
                  v-if="canAccessGroup"
                  :to="{
                    name: 'GroupShow',
                    params: { groupId: child.group_id },
                    query: { tab: 'children' },
                  }"
                  class="hover-underlined c-light-black fw-500"
                  target="_blank"
                >
                  <span> #{{ child.external_id }} </span>
                </router-link>
                <span class="px-3">/</span>
                <router-link
                  v-if="canAccessGroup"
                  :to="$route"
                  class="hover-underlined c-light-black fw-500"
                >
                  <span>
                    {{ childName }}
                  </span>
                </router-link>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="d-flex align-center">
                <div data-cy="page-title">
                  <span
                    v-text="childName"
                    class="d-inline-block fs-22 fw-600 me-3"
                    data-testid="subsidy-name"
                  />
                </div>

                <template v-if="displayPriority">
                  <v-btn
                    @click="priorityDialogIsVisible = true"
                    :aria-label="$t('Priority')"
                    class="ms-0"
                    color="primary"
                    size="small"
                    variant="text"
                    icon
                    rounded
                  >
                    <v-icon>{{ subsidy.priority ? 'star' : 'star_border' }}</v-icon>
                  </v-btn>
                </template>

                <div
                  v-if="displayLabels"
                  class="ms-0"
                >
                  <LabelValue
                    @edit="$refs.subsidyLabelDialog.open(subsidy)"
                    :applied-labels="subsidy.relationships.labels.data"
                    :disabled="processing"
                  />
                </div>

                <v-tooltip
                  v-if="!subsidyProgram.disable_subsidy_unlocking"
                  location="bottom"
                >
                  <template #activator="{ props }">
                    <v-btn
                      @click="toggleLock"
                      v-bind="props"
                      class="ms-0"
                      color="primary"
                      data-cy="lock-button"
                      data-testid="lock-button"
                      size="small"
                      variant="text"
                      icon
                    >
                      <v-icon>{{ subsidy.locked ? 'lock' : 'lock_open' }}</v-icon>
                    </v-btn>
                  </template>
                  <span
                    v-t="
                      subsidy.locked
                        ? 'The application is locked. The applicant cannot make changes.'
                        : 'The application is unlocked. The applicant can make changes.'
                    "
                  />
                </v-tooltip>

                <template v-if="displayPriorityScore">
                  <v-btn
                    @click="scoreDialogIsVisible = true"
                    :aria-label="$t('Score')"
                    class="ms-3"
                    color="grey"
                    size="small"
                    variant="flat"
                    rounded
                  >
                    <v-icon
                      class="me-2"
                      role="presentation"
                      aria-hidden
                    >
                      assessment
                    </v-icon>
                    <span>{{ subsidy.score || '-' }}</span>
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </div>

          <div
            class="d-flex flex-wrap justify-end flex-0-1"
            data-testid="right-bar-toggle"
          >
            <template v-if="$store.state.profile.org_subsidies_access">
              <v-btn-toggle
                v-model="rightBarTab"
                class="me-3"
                selected-class="bg-primary c-white"
                variant="outlined"
                divided
                tile
              >
                <v-btn
                  v-if="$store.state.pages.Subsidies.features.enable_reviews"
                  aria-label="Reviews"
                  value="reviews"
                  variant="outlined"
                  icon
                >
                  <v-icon
                    :color="rightBarTab == 'reviews' ? 'white' : 'black'"
                    class="material-icons-outlined"
                  >
                    reviews
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="$store.state.pages.Subsidies.features.enable_messaging"
                  aria-label="Messaging"
                  value="messaging"
                  variant="outlined"
                  icon
                >
                  <v-badge
                    :content="unreadMessageCount"
                    :model-value="!!unreadMessageCount"
                    color="red"
                  >
                    <v-icon
                      :color="rightBarTab == 'messaging' ? 'white' : 'black'"
                      class="material-icons-outlined"
                    >
                      forum
                    </v-icon>
                  </v-badge>
                </v-btn>
                <v-btn
                  v-if="$store.state.pages.Subsidies.features.enable_notes"
                  aria-label="Notes"
                  value="notes"
                  variant="outlined"
                  icon
                >
                  <v-icon
                    :color="rightBarTab == 'notes' ? 'white' : 'black'"
                    class="material-icons-outlined"
                  >
                    assignment
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>

            <v-menu
              v-if="displayStages"
              v-model="openStageDialog"
              :min-width="350"
              max-width="500"
              offset="5"
            >
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  :aria-label="$t('Stages')"
                  color="primary"
                  data-cy="stage-selector"
                  size="large"
                  variant="outlined"
                  tile
                >
                  <span v-t="currentStage.title" />
                  <v-icon end> keyboard_arrow_down </v-icon>
                </v-btn>
              </template>

              <v-list
                v-model:selected="currentStageId"
                class="bc-light-gray b-1 py-0"
                tile
              >
                <template
                  v-for="(stage, index) in subsidyProgram.stages"
                  :key="stage.id"
                >
                  <v-list-item
                    :class="{
                      'bg-primary-extra-light':
                        subsidy.stage_id == stage.id || currentStageIndex == index,
                    }"
                    :data-cy="'stage-' + index"
                    :value="stage.id"
                    lines="two"
                  >
                    <v-list-item-title>{{ $t(stage.title) }}</v-list-item-title>
                    <template #append>
                      <template v-if="index < currentStageIndex">
                        <v-hover v-slot="{ isHovering, props }">
                          <v-btn
                            @click="setStage(index)"
                            v-bind="props"
                            :loading="processing"
                            :variant="isHovering ? 'outlined' : 'text'"
                            color="primary"
                            data-cy="set-stage-button"
                            icon
                          >
                            <v-icon color="primary"> check </v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                      <template
                        v-else-if="
                          currentStageIndex < subsidyProgram.stages.length - 1 &&
                          (subsidy.stage_id === stage.id || currentStageIndex === index)
                        "
                      >
                        <v-btn
                          @click="completeStage()"
                          :loading="processing"
                          class="ms-4"
                          color="primary"
                          data-cy="complete-stage-button"
                        >
                          <span v-t="'Complete'" />
                        </v-btn>
                      </template>
                      <template v-else-if="subsidyProgram.enable_skipping_stages">
                        <v-hover v-slot="{ isHovering, props }">
                          <v-btn
                            @click="setStage(index)"
                            v-bind="props"
                            :loading="processing"
                            color="grey-lighten-3"
                            data-cy="set-stage-button"
                            variant="outlined"
                            icon
                          >
                            <v-icon
                              v-if="isHovering"
                              color="primary-lighten-2"
                            >
                              check
                            </v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < subsidyProgram.stages.length - 1"
                    :key="index"
                  />
                </template>
              </v-list>
            </v-menu>
          </div>
          <template v-if="actions.length > 0">
            <ActionMenu
              @click:action:delete="destroy"
              @click:action:lock:preferences="updatePreferencesUnlocked(false)"
              @click:action:unlock:preferences="updatePreferencesUnlocked(true)"
              :items="actions"
              button-class="ms-3"
              button-icon="more_vert"
              x-small
            />
          </template>
        </div>

        <template #extension>
          <v-tabs slider-color="primary">
            <template v-if="tabs.includes('Application')">
              <v-menu
                v-if="reviewModeEnabled"
                location="bottom"
              >
                <template #activator="{ props }">
                  <v-tab
                    v-bind="props"
                    :to="{ query: { tab: 'Application' } }"
                    class="fs-16 ls-normal tt-none"
                    data-cy="tab-application"
                    data-testid="tab-application"
                    exact
                  >
                    <span v-t="'Application'" />
                    <v-icon
                      v-if="subsidyProgram.enable_reviewer_sequence"
                      class="ms-2"
                    >
                      expand_more
                    </v-icon>
                  </v-tab>
                </template>

                <v-list tile>
                  <v-list-item
                    v-for="(section, index) in subsidyProgram.reviewer_question_sequence"
                    @click="openSection({ index })"
                    :key="index"
                  >
                    <v-list-item-title>{{ section.title }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openSection({ title: 'Documents' })">
                    <v-list-item-title>Documents</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <template v-else>
                <v-tab
                  :to="{ query: { tab: 'Application' } }"
                  class="fs-16 ls-normal tt-none"
                  data-cy="tab-application"
                  data-testid="tab-application"
                  exact
                >
                  <span v-t="'Application'" />
                </v-tab>
              </template>
            </template>

            <template v-if="tabs.includes('Enrollment')">
              <v-tab
                :to="{ query: { tab: 'Enrollment' } }"
                class="fs-16 ls-normal tt-none"
                data-cy="tab-enrollment"
                exact
              >
                <span v-t="'Enrollment'" />
              </v-tab>
            </template>

            <template v-if="tabs.includes('Funding')">
              <v-tab
                :to="{ query: { tab: 'Funding' } }"
                class="fs-16 ls-normal tt-none"
                data-cy="tab-funding"
                exact
              >
                <span v-t="'Funding'" />
              </v-tab>
            </template>
          </v-tabs>
        </template>
      </v-toolbar>

      <div class="bg-super-light-blue fill-height d-flex">
        <div
          :style="rightBarTab ? 'width: 65%;' : 'width: 100%'"
          class="fill-height px-3"
          style="overflow-y: auto; padding-top: 142px"
        >
          <v-card
            class="mb-4"
            border
            tile
          >
            <v-card-title class="fs-22 fw-600">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col
                  class="d-flex justify-space-between"
                  cols="12"
                >
                  <div class="d-flex align-center">
                    Eligibility Status
                    <v-chip
                      :color="eligibilityIconColor"
                      class="ms-3 badge-style justify-center"
                      variant="flat"
                      rounded
                    >
                      <v-icon
                        color="white"
                        role="presentation"
                        aria-hidden
                      >
                        {{ eligibilityIcon }}
                      </v-icon>
                    </v-chip>
                  </div>
                  <div>
                    <v-btn
                      @click="eligibilityVisible = !eligibilityVisible"
                      :aria-label="$t('Toggle visibility')"
                      class="ms-0"
                      color="primary"
                      size="small"
                      variant="text"
                      icon
                      rounded
                    >
                      <v-icon
                        class="material-icons-outlined"
                        color="navy-blue"
                      >
                        {{ eligibilityVisible ? 'visibility_off' : 'visibility' }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider
              v-if="eligibilityVisible"
              class="mb-3"
            />
            <v-card-text v-if="eligibilityVisible">
              <SubsidyEligibilityCardV2
                :elevation="0"
                :funding-sources="subsidy.meta.funding_sources"
                :projected-eligibility="subsidy.projected_eligibility"
              />
            </v-card-text>
          </v-card>

          <v-window v-model="tab">
            <v-window-item
              class="bg-transparent"
              value="Application"
            >
              <SubsidyApplication
                v-model:child-data="child"
                v-model:group-data="group"
                v-model:review-mode-enabled="reviewModeEnabled"
                v-model:subsidy="subsidy"
                v-model:subsidy-program="subsidyProgram"
                @created-enrollment="handleCreatedEnrollment"
                @load-enrollments="handleLoadEnrollments"
                @submit="$refs.submitDialog.open()"
                @update-subsidy="(subsidy) => handleUpdateSubsidy(subsidy)"
                ref="application"
                :enrollments="enrollments"
              />

              <ResourceDialog
                @save="submitApp"
                ref="submitDialog"
                :max-width="800"
                :processing="processing"
                title="Submit application"
              >
                <template #form="{ localValue }">
                  <template v-if="displayCustomSubmissionDate">
                    <div class="b-1 bc-very-light-grey b-radius-4 pa-md-6 pa-3 mb-4">
                      <LabeledDatePicker
                        v-model="localValue.submitted_at"
                        :disallow-after-date="new Date().toISOString()"
                        message="Submission date"
                        placeholder-text="Today"
                      />
                    </div>
                  </template>
                  <UserAttestation
                    @change="localValue.attestation = $event"
                    ref="adminAttestation"
                    :attestation-label="
                      subsidyProgram.admin_attestation_label[$store.state.profile.default_locale]
                    "
                    :attestation-text="subsidyProgram.admin_attestation_text"
                    :enable-esign="subsidyProgram.enable_admin_esign"
                  />
                </template>
              </ResourceDialog>
            </v-window-item>

            <v-window-item
              class="bg-transparent"
              value="Enrollment"
            >
              <SubsidyEnrollment
                @change:enrollments="loadSubsidy"
                @created-enrollment="handleCreatedEnrollment"
                @load-enrollments="handleLoadEnrollments"
                @update-subsidy="(updatedSubsidy) => handleUpdateSubsidy(updatedSubsidy)"
                :enrollments="enrollments"
                :subsidy="subsidy"
                :subsidy-program="subsidyProgram"
              />
            </v-window-item>

            <v-window-item
              class="bg-transparent"
              value="Funding"
            >
              <SubsidyFunding
                :subsidy="subsidy"
                :subsidy-program="subsidyProgram"
              />
            </v-window-item>
          </v-window>
          <VerticalSpacer :min-height="60" />
        </div>

        <template v-if="$store.state.profile.org_subsidies_access">
          <v-card
            :style="rightBarTab ? 'width: 35%;' : 'display: none;'"
            class="fill-height"
            style="overflow-y: auto; padding-top: 118px"
            border
          >
            <v-window
              v-model="rightBarTab"
              :mandatory="false"
              eager
            >
              <v-window-item
                key="messaging"
                value="messaging"
              >
                <SubsidyCommunications
                  @close="rightBarTab = null"
                  :group-id="subsidy.group_id"
                >
                  <template #messages>
                    <SubsidyMessages
                      @message-read="
                        unreadMessageCount = unreadMessageCount == 0 ? 0 : unreadMessageCount - 1
                      "
                      :message-templates="subsidyProgram.message_templates"
                      :show-header="true"
                      :subsidy="subsidy"
                    />
                  </template>
                </SubsidyCommunications>
              </v-window-item>

              <v-window-item
                key="notes"
                value="notes"
              >
                <SubsidyNotes
                  @close="rightBarTab = null"
                  :subsidy="subsidy"
                />
              </v-window-item>

              <v-window-item
                v-if="$store.state.pages.Subsidies.features.enable_reviews"
                key="reviews"
                value="reviews"
              >
                <ReviewsList
                  @close="rightBarTab = null"
                  @published="load"
                  :associated-schema-ids="subsidyProgram.associated_schema_ids"
                  :owner="subsidy"
                  :owner-type="'Subsidy'"
                  :statuses="subsidyProgram.subsidy_statuses"
                />
              </v-window-item>
            </v-window>
          </v-card>
        </template>
      </div>

      <v-dialog
        v-model="scoreDialogIsVisible"
        max-width="500"
        scrim="transparent"
      >
        <v-card border>
          <v-card-title>Edit score</v-card-title>
          <v-card-text>
            <v-row>
              <LabeledTextfield v-model.number="subsidy.score" />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="scoreDialogIsVisible = false"
              size="x-large"
              variant="text"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              @click="save"
              :loading="processing"
              color="primary"
              size="x-large"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="priorityDialogIsVisible"
        max-width="400"
        scrim="transparent"
      >
        <v-card border>
          <v-card-title>{{ $t(`Prioritize ${terms.subsidy.toLowerCase()}`) }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div
                  v-t="'What level of priority?'"
                  class="fw-500 fs-16 mb-2"
                  cols="12"
                />
                <v-select
                  v-model="subsidy.priority"
                  :items="['High', 'Medium', 'Low']"
                  placeholder="Select a priority"
                  variant="filled"
                  hide-details
                />
              </v-col>
              <LabeledTextarea
                v-model="subsidy.priority_reason"
                message="Priority reason"
              />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="priorityDialogIsVisible = false"
              variant="text"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              @click="save()"
              :loading="processing"
              color="primary"
            >
              <span>Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <LabelDialog
        @change="updateSubsidyLabels($event)"
        ref="subsidyLabelDialog"
        :program-labels="subsidyProgramLabels"
        label-owner-type="Subsidy"
      />
    </template>
  </div>
</template>
<script setup>
import { getTabsForSubsidyStage } from '@/specialist/services/subsidy_review';
import { handleError } from '@/shared/services/api';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import LabelDialog from '../components/LabelDialog.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import ReviewsList from '@/specialist/components/ReviewsList.vue';
import SubsidyApplication from '@/specialist/components/subsidy/SubsidyApplication.vue';
import SubsidyApplicationData from '@/shared/services/subsidy/application-data.js';
import SubsidyCommunications from '@/specialist/components/subsidy/SubsidyCommunications.vue';
import SubsidyEligibilityCardV2 from '@/shared/components/subsidy/SubsidyEligibilityCardV2.vue';
import SubsidyEnrollment from '@/specialist/components/subsidy/SubsidyEnrollment.vue';
import SubsidyFunding from '@/specialist/components/subsidy/SubsidyFunding.vue';
import SubsidyMessages from '@/specialist/components/subsidy/SubsidyMessages.vue';
import SubsidyNotes from '@/specialist/components/subsidy/SubsidyNotes.vue';
import useEventBus from '@/shared/composables/useEventBus';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

const { terms } = useTerms();
const { updateQuery } = useRouterHelper();
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const child = ref();
const group = ref();
const reviewModeEnabled = ref(false);
const subsidy = ref();
const subsidyProgram = ref();

const adminAttestation = ref(null);
const application = ref(null);
const attestationRequired = ref(null);
const currentStageId = ref(null);
const eligibilityVisible = ref(false);
const enrollments = ref([]);
const loading = ref(true);
const openStageDialog = ref(false);
const priorityDialogIsVisible = ref(false);
const processing = ref(false);
const rightBarTab = ref(null);
const rightBarVisible = ref(false);
const scoreDialogIsVisible = ref(false);
const subsidyProgramId = ref(null);
const subsidyProgramLabels = ref([]);
const tab = ref(null);
const unreadMessageCount = ref(0);

const actions = computed(() => {
  if (!subsidy.value) return [];

  const ary = [];
  if (store.state.profile.org_subsidies_delete) {
    ary.push({ event: 'delete', avatar: 'delete', title: 'Delete application' });
  }
  if (store.state.profile.org_subsidies_admin) {
    if (subsidy.value.preferences_unlocked) {
      ary.push({ event: 'lock:preferences', avatar: 'lock', title: 'Lock preferences' });
    } else {
      ary.push({
        event: 'unlock:preferences',
        avatar: 'lock_open',
        title: 'Unlock preferences',
      });
    }
  }
  return ary;
});

const backToIndexQuery = computed(() => {
  const query = route.query?.from;

  if (query) {
    return { page: query };
  }

  return null;
});

const backToIndexWithSubsidyProgram = computed(() => {
  const fromPage = backToIndexQuery.value ? backToIndexQuery.value : {};
  return { subsidyProgramId: subsidyProgram.value?.id, ...fromPage };
});

const canAccessEnrollments = computed(() => {
  return (
    store.state.profile.org_enrollments_access ||
    store.state.profile.org_enrollments_admin ||
    store.state.profile.org_enrollments_agent
  );
});

const canAccessGroup = computed(() => {
  return store.state.profile.org_groups_access || store.state.profile.org_subsidies_agent;
});

const currentStage = computed(() => {
  return subsidyProgram.value?.stages[currentStageIndex.value] || {};
});

const childName = computed(() => {
  // dynamically update as 2-way-binding of child updates
  return `${child.value.first_name} ${child.value.last_name}`;
});

const currentStageIndex = computed(() => {
  if (subsidyProgram.value && subsidy.value) {
    const index = subsidyProgram.value.stages.findIndex(
      (stage) => subsidy.value.stage_id === stage.id,
    );
    if (index === -1) return 0;
    return index;
  }
  return 0;
});

const displayCustomSubmissionDate = computed(() => {
  return (
    store.state.profile.org_subsidies_access &&
    (subsidyProgram.value.enable_set_application_submitted_at_date ||
      !subsidyProgram.value.enable_deferred_acceptance)
  );
});

const displayLabels = computed(() => {
  return store.state.profile.org_subsidies_access && subsidyProgramLabels.value.length > 0;
});

const displayPriority = computed(() => {
  return store.state.profile.org_subsidies_access && subsidyProgram.value.enable_priority;
});

const displayPriorityScore = computed(() => {
  return (
    store.state.profile.org_subsidies_access &&
    subsidyProgram.value.enable_scoring &&
    subsidyProgram.value.display_priority_score
  );
});

const displayStages = computed(() => {
  return (
    store.state.profile.org_subsidies_access &&
    subsidyProgram.value.enable_stages &&
    subsidyProgram.value.stages.length > 0
  );
});

const eligibilityIcon = computed(() => {
  return subsidy.value.projected_eligibility ? 'check_circle' : 'cancel';
});

const eligibilityIconColor = computed(() => {
  return subsidy.value.projected_eligibility ? 'green-500' : 'red';
});

const loaded = computed(() => {
  return child.value && group.value && subsidy.value && subsidyProgram.value;
});

const tabs = computed(() => {
  return getTabsForSubsidyStage(subsidyProgram.value, currentStage.value, {
    profile: store.state.profile,
  });
});

const afterLoaded = function () {
  setDefaultRightBarTab();
};

async function completeStage() {
  subsidy.value.stage_id = subsidyProgram.value.stages[currentStageIndex.value + 1].id;
  await save();
}

function destroy() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to delete this application? This cannot be undone.')) {
    Api.organization.subsidy.destroy(
      route.params.id,
      () => {
        eventBus.chime('Deleted successfully');
        router.replace({ name: 'SubsidyIndex' });
      },
      handleError, // TODO: Add this to the endpoint
    );
  }
}

function handleCreatedEnrollment(enrollment) {
  enrollments.value.push(enrollment);
}

function handleLoadEnrollments(enrollmentsFromApi) {
  if (canAccessEnrollments.value) {
    enrollments.value = enrollmentsFromApi;
  }
}

async function handleUpdateSubsidy(updatedSubsidy) {
  subsidy.value = updatedSubsidy;
  await load();
}

async function load() {
  await loadSubsidy();
  if (store.state.pages.Subsidies.features.enable_messaging) {
    await loadUnreadMessageCount();
  }
  await loadSubsidyProgram();
  await loadSubsidyProgramLabels();
  await loadChild();
  await loadGroup();
}

async function loadUnreadMessageCount() {
  const resp = await Api.organization.message.index({
    days: 10000,
    group_id: subsidy.value.group_id,
    unread: true,
  });
  if (!resp.data) return;
  const unreadParentMessages = resp.data.filter((message) => !message.meta.member_is_specialist);
  unreadMessageCount.value = unreadParentMessages.length;
}

async function loadChild() {
  const resp = await SubsidyApplicationData.getChildData(subsidy.value.id);
  child.value = resp.data;
}

async function loadGroup() {
  const resp = await SubsidyApplicationData.getGroupData(subsidy.value.id);
  group.value = resp.data;
}

async function loadSubsidy() {
  const resp = await Api.organization.subsidy.get(route.params.id);
  subsidy.value = resp.data;
  currentStageId.value = subsidy.value.stage_id;
  subsidyProgramId.value = subsidy.value.subsidy_program_id;
}

async function loadSubsidyProgram() {
  const resp = await Api.organization.subsidy_program.get(subsidyProgramId.value);
  subsidyProgram.value = resp.data;
  reviewModeEnabled.value = subsidyProgram.value.enable_reviewer_sequence;
  attestationRequired.value =
    subsidyProgram.value.admin_attestation_text[store.state.profile.default_locale];
}

async function loadSubsidyProgramLabels() {
  if (!store.state.profile.org_subsidies_access) return;

  const resp = await Api.organization.label.index({
    subsidy_program_id: subsidyProgram.value.id,
  });
  subsidyProgramLabels.value = resp.data;
}

function openSection(section) {
  application.value.openSection(section);
}

async function save() {
  processing.value = true;
  Api.organization.subsidy.update(
    subsidy.value.id,
    subsidy.value,
    (resp) => {
      processing.value = false;
      subsidy.value = resp.data;
      priorityDialogIsVisible.value = false;
      scoreDialogIsVisible.value = false;
      eventBus.chime('Application saved');
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

async function submitApp(submission) {
  let revision_author_signed_name;
  let revision_author_signature_image;
  let revision_description;

  if (attestationRequired.value) {
    if (subsidyProgram.value.enable_admin_esign) {
      if (submission.attestation) {
        revision_author_signed_name = adminAttestation.value.esignName;
        revision_author_signature_image = adminAttestation.value.getEsignSignature();
        revision_description = adminAttestation.value.getAttestationText();
      } else {
        return eventBus.chime('Adopt a signature to submit');
      }
    } else if (!submission.attestation) {
      return eventBus.chime('Agree to terms to submit');
    }
  }

  const params = {
    revision_author_signed_name,
    revision_author_signature_image,
    revision_description,
    submitted_at: submission.submitted_at,
  };

  processing.value = true;
  const resp = await Api.organization.subsidy
    .submit(subsidy.value.id, params)
    .catch((err) => eventBus.chime(err.response.data.errors[0]));

  processing.value = false;
  if (!resp.data) return null;

  subsidy.value = resp.data;
  window.location.reload();
  return eventBus.chime('Application submitted');
}

function setDefaultRightBarTab() {
  if (subsidyProgram.value) {
    if (currentStage.value.enable_reviews && store.state.profile.org_subsidies_access) {
      rightBarTab.value = 'reviews';
    } else {
      rightBarTab.value = null;
    }
  }
}

async function setStage(index) {
  if (index < currentStageIndex.value) {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to move back to a previous stage?')) {
      subsidy.value.stage_id = subsidyProgram.value.stages[index].id;
      await save();
    }
  } else if (subsidyProgram.value.enable_skipping_stages) {
    if (index === currentStageIndex.value + 1) {
      // Selecting next stage is logically equivalent to completing current stage
      await completeStage();
      // eslint-disable-next-line no-alert
    } else if (confirm('Are you sure you want to skip stages?')) {
      subsidy.value.stage_id = subsidyProgram.value.stages[index].id;
      await save();
    }
  }
}

function toggleLock() {
  if (!store.state.profile.org_subsidies_access) {
    if (subsidy.value.locked) eventBus.longChime('Contact administrator to unlock application');
    return;
  }

  update({ locked: !subsidy.value.locked }, subsidy.value.locked ? 'Unlocked' : 'Locked');
}

function update(newVal, msg) {
  Api.organization.subsidy.update(
    subsidy.value.id,
    newVal,
    (resp) => {
      subsidy.value = resp.data;
      if (msg) {
        eventBus.chime(msg);
      }
      processing.value = false;
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
      processing.value = false;
    },
  );
}

function updatePreferencesUnlocked(newVal) {
  update({ preferences_unlocked: newVal });
}

function updateSubsidyLabels(updatedSubsidy) {
  subsidy.value.relationships.labels.data = updatedSubsidy.relationships.labels.data;
}

watch(currentStageIndex, setDefaultRightBarTab, { immediate: true });
watch(loaded, afterLoaded, { immediate: true });
watch(rightBarTab, (newVal) => {
  if (newVal != null) {
    rightBarVisible.value = true;
  } else {
    rightBarVisible.value = false;
  }
});

// probably use onMount instead?
watch(
  () => route.params.id,
  async (newVal) => {
    if (newVal) {
      loading.value = true;
      await load();
    }
  },
  { immediate: true },
);
watch(
  () => route.query.tab,
  (newVal) => {
    if (newVal) {
      tab.value = newVal;
    }
  },
  { immediate: true },
);
watch(tabs, async (newVal) => {
  if (newVal.length > 0 && !tab.value) {
    // Default to using the first tab when multiple tabs are available
    [tab.value] = newVal;

    if (route.query?.from) {
      await updateQuery({ tab: tab.value, from: route.query.from });
    }

    await updateQuery({ tab: tab.value });
  }
});
</script>

<style>
.badge-style {
  min-width: 56px;
}
</style>
