<template>
  <div>
    <ResourceDialog
      @save="createCustomView"
      ref="editorDialog"
      :max-width="600"
      :processing="processing"
      cancel-button-text="Close"
      save-button-text="New view"
      title="Manage custom views"
    >
      <template #append>
        <v-data-table
          :cell-props="{ style: 'border-bottom: none;' }"
          :headers="tableHeaders"
          :items="customViews"
          hide-default-footer
          hide-default-header
        >
          <template #item.actions="{ item }">
            <div class="d-flex justify-end align-center">
              <ActionMenu
                @click:action:delete="destroyCustomView(item)"
                @click:action:edit="editCustomView(item)"
                :items="actionItems"
                button-icon="more_vert"
              />
            </div>
          </template>
        </v-data-table>
      </template>
    </ResourceDialog>

    <CustomViewColumnsEditor
      @save="handleCustomViewColumnsUpdate"
      ref="customViewColumnsEditor"
      :allowed-properties="allowedProperties"
      :processing="processing"
      named
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import CustomViewColumnsEditor from '@/specialist/components/custom-views/CustomViewColumnsEditor.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const emit = defineEmits(['reload', 'save']);

defineExpose({ open, close });

const props = defineProps({
  allowedProperties: {
    type: Array,
    default: () => [],
  },
  customViews: {
    type: Array,
    default: () => [],
  },
});

const confirmDialog = ref(null);
const customViewColumnsEditor = ref(null);
const editorDialog = ref(null);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'attributes.name',
  },
  {
    title: '',
    value: 'actions',
  },
]);
const processing = ref(false);

const actionItems = computed(() => {
  const items = [
    {
      title: 'Edit',
      event: 'edit',
    },
  ];

  if (props.customViews.length === 1) return items;

  items.push({
    title: 'Delete',
    event: 'delete',
  });

  return items;
});

function close() {
  editorDialog.value.close();
}

async function handleCustomViewColumnsUpdate(newValue) {
  const { columns, name } = newValue;
  emit('save', { columns, name });
  customViewColumnsEditor.value.close();
}

function open(existingValues) {
  editorDialog.value.open(existingValues);
}

async function createCustomView() {
  eventBus.chime('Creating new views will be added in CARE-1644');
}

async function editCustomView(customView) {
  const { columns, name } = customView.attributes;
  customViewColumnsEditor.value.open({ columns, name });
}

async function destroyCustomView(customView) {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to delete this custom view? This cannot be undone.',
  );
  if (!confirm) return;

  processing.value = true;

  const response = await Api.organization.formCustomView.destroy(customView.id);

  processing.value = false;

  if (response?.status !== 200) return;

  eventBus.chime('Deleted');
  emit('reload');
}
</script>
