<template>
  <div
    v-if="$store.state.site"
    id="app"
    :class="{ 'fill-height': $route.meta.fillHeight }"
  >
    <v-app :class="{ 'fill-height': $route.meta.fillHeight }">
      <template v-if="hasProfile && !$route.meta.registration">
        <v-navigation-drawer
          v-model="drawer"
          :class="{ rail: rail }"
          :rail="rail"
          color="white"
          rail-width="70"
        >
          <v-list-item class="px-4 py-3">
            <router-link :to="{ name: 'Dashboard' }">
              <template v-if="rail">
                <v-img
                  :alt="$t(`Homepage for ${$store.state.brand.name}`)"
                  :src="$a.url('logo.small')"
                  class="mx-auto"
                  max-width="30"
                />
              </template>

              <template v-else>
                <v-img
                  :alt="$t(`Homepage for ${$store.state.brand.name}`)"
                  :src="$store.state.brand.logo.small || $store.state.brand.logo.full"
                  max-height="50"
                  max-width="140"
                />
              </template>
            </router-link>
          </v-list-item>

          <transition
            :name="transitionName"
            mode="out-in"
          >
            <MainNavBar />
          </transition>

          <template #append>
            <v-divider class="w-90pc mx-auto" />

            <div class="mini-nav-toggle">
              <v-btn
                v-if="!$vuetify.display.mobile"
                @click="toggleMiniNav"
                :aria-label="$t('Expand or minimize navigation')"
                :icon="miniNav ? 'chevron_right' : 'chevron_left'"
                class="d-flex bg-very-light-grey"
                color="light-black"
                size="20"
              />
            </div>

            <v-list>
              <v-menu
                location="top"
                open-on-click
              >
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    :class="{ rail: rail }"
                  >
                    <v-list-item-title class="d-flex align-center">
                      <v-icon
                        :class="{ 'me-3': !rail }"
                        class="c-black material-icons-round"
                        icon="account_circle"
                      />

                      <span
                        v-if="!rail"
                        class="fs-16 fw-500"
                      >
                        {{ $t('My account') }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </template>

                <v-list
                  class="border mb-2"
                  elevation="0"
                >
                  <v-list-item
                    v-if="
                      $store.state.profile.org_settings_access && !$route.path.startsWith('/admin/')
                    "
                    id="account_admin_console"
                    :to="{ name: 'AdminSettings' }"
                    link
                  >
                    <v-list-item-title class="fw-500 fs-16">
                      {{ $t('Admin console') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="$route.path.startsWith('/admin/')"
                    id="account_exit_admin"
                    :to="{ name: 'Dashboard' }"
                    link
                  >
                    <v-list-item-title class="fw-500 fs-16">
                      {{ $t('Exit admin console') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    id="account_profile"
                    :to="{ name: 'Profile' }"
                    link
                  >
                    <v-list-item-title class="fw-500 fs-16">
                      {{ $t('My profile') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    id="account_terms"
                    href="https://www.getbridgecare.com/terms-of-service"
                    target="_blank"
                    link
                  >
                    <v-list-item-title class="fs-16 fw-500">
                      {{ $t('Terms of Service') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    id="account_privacy"
                    href="https://www.getbridgecare.com/privacy-policy"
                    target="_blank"
                    link
                  >
                    <v-list-item-title class="fs-16 fw-500">
                      {{ $t('Privacy Policy') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="customLink1"
                    id="link_1_link"
                    :href="customLink1['url']"
                    target="_blank"
                  >
                    <v-list-item-title class="fs-16 fw-500">
                      {{ $t(customLink1['name']) }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="customLink2"
                    id="link_2_link"
                    :href="customLink2['url']"
                    target="_blank"
                  >
                    <v-list-item-title class="fs-16 fw-500">
                      {{ $t(customLink2['name']) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-list-item
                @click="signOut"
                :class="{ rail: rail }"
              >
                <v-list-item-title class="d-flex align-center">
                  <v-icon
                    :class="{ 'me-3': !rail }"
                    class="material-icons-outlined c-black"
                    icon="exit_to_app"
                    size="22"
                  />

                  <span
                    v-if="!rail"
                    class="fs-16 fw-500"
                  >
                    {{ $t('Sign out') }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-navigation-drawer>
      </template>

      <template v-else-if="!$route.meta.disableAppBar">
        <v-app-bar
          id="navbar"
          class="c-light-black bg-white"
          elevation="1"
          height="70"
        >
          <v-row class="d-flex align-center">
            <v-col class="d-flex justify-start">
              <img
                :alt="$t(`Homepage for ${$store.state.brand.name}`)"
                :src="$store.state.brand.logo.full"
                class="mxh-50 mxw-100pc"
              />
            </v-col>
          </v-row>
        </v-app-bar>
      </template>

      <v-app-bar-nav-icon
        v-if="hasProfile && $vuetify.display.mobile"
        @click.stop="drawer = !drawer"
        aria-label="Select nav bar"
        data-cy="select_nav_bar"
      />

      <SnackBar
        v-model="chime"
        :centered="true"
        :text="chimeText"
        :timeout="chimeTime"
        rounded
      />

      <v-main class="fill-height bg-super-light-blue">
        <router-view
          @chime="regularChime"
          @error="error"
          @replace="$router.push($event)"
          v-slot="{ Component }"
        >
          <transition
            :name="transitionName"
            mode="out-in"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </v-main>

      <UserSessionTimeout />
    </v-app>
  </div>
</template>

<script>
import MainNavBar from '@/specialist/components/navigation/MainNavBar.vue';
import UserSessionTimeout from '@/shared/components/UserSessionTimeout.vue';
import useSegmentPageViews from '@/shared/composables/analytics/useSegmentPageViews';
import chime from '@/shared/mixins/chime';
import SnackBar from '@/shared/components/SnackBar.vue';
import SegmentService from '@/shared/services/analytics/SegmentService';
import { SEGMENT_PROVIDE_KEY } from '@/shared/composables/analytics/useSegment';
import { provide } from 'vue';
import vuetify from '@/plugins/vuetify';

export default {
  compatConfig: { MODE: 2 },

  components: {
    MainNavBar,
    SnackBar,
    UserSessionTimeout,
  },

  mixins: [chime],

  setup() {
    const segment = SegmentService.forSpecialist(window.analytics);
    provide(SEGMENT_PROVIDE_KEY, segment);
    useSegmentPageViews(segment);
  },

  data() {
    return {
      drawer: null,
      miniNav: null,
      transitionName: null,
    };
  },

  computed: {
    customLink1() {
      if (!this.$store.state.pages.Navigation) return null;
      if (!this.$store.state.pages.Navigation?.link_1_url) return null;
      if (!this.$store.state.pages.Navigation?.link_1_name) return null;

      return {
        name: this.$store.state.pages.Navigation.link_1_name,
        url: this.$store.state.pages.Navigation.link_1_url,
      };
    },

    customLink2() {
      if (!this.$store.state.pages.Navigation) return null;
      if (!this.$store.state.pages.Navigation?.link_2_url) return null;
      if (!this.$store.state.pages.Navigation?.link_2_name) return null;

      return {
        name: this.$store.state.pages.Navigation.link_2_name,
        url: this.$store.state.pages.Navigation.link_2_url,
      };
    },

    hasProfile() {
      return (
        this.$store.state.profile &&
        this.$store.state.profile.organization_id === window.organization_id
      );
    },

    rail() {
      return this.$store.state.miniNav && !vuetify.display.mobile.value;
    },
  },

  watch: {
    $route(to, from) {
      this.$store.commit('setAppBarBack', null);
      this.$store.commit('setAppBarChip', null);
      if (to.meta.depth > from.meta.depth) {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    },

    miniNav(value) {
      this.$store.commit('setMiniNav', value);
    },

    drawer(value) {
      this.$store.commit('setDrawer', value);
    },
  },

  mounted() {
    if (localStorage.getItem('miniNav')) {
      try {
        this.miniNav = JSON.parse(localStorage.miniNav);
      } catch (_error) {
        localStorage.removeItem('miniNav');
      }
    } else {
      this.miniNav = false;
    }
  },

  methods: {
    signOut() {
      this.$router.push({ name: 'SignOut' });
    },

    toggleMiniNav() {
      this.miniNav = !this.miniNav;
      localStorage.setItem('miniNav', JSON.stringify(this.miniNav));
    },
  },
};
</script>

<style>
.rail.v-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-nav-toggle {
  height: 30px;
  width: 30px;
  position: absolute;
  right: -20px;
  bottom: 92px;
  opacity: 0;
  transition: opacity 0.5s ease 3s;
  z-index: 100;
}
.mini-nav-toggle:hover {
  opacity: 1;
  transition-delay: 0s;
}
</style>
