<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle>
      <v-btn
        v-if="isAnyReportFolderSelected()"
        :to="{ name: 'ReportIndex' }"
        class="ms-3"
        variant="text"
        icon
      >
        <v-icon
          class="c-black"
          icon="west"
          size="28"
        />
      </v-btn>
      <div
        v-text="getTitle()"
        class="ms-4 me-3 d-inline-block fs-22 fw-600"
        data-cy="page-title"
      />
      <ActionMenu
        v-if="$store.state.profile.org_reporting_admin && isAnyReportFolderSelected()"
        @click:action:delete="destroyReportFolder()"
        @click:action:edit="openEditReportFolderDialog()"
        :items="getReportFolderActionItems()"
        button-class="ms-3"
        button-icon="more_vert"
        x-small
      />
    </PageTitle>

    <v-toolbar
      class="mb-3 px-4 bb-1"
      flat
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="query"
            :aria-label="$t('Search reports by name')"
            :placeholder="$t('Search reports by name')"
            data-cy="search-bar"
            data-testid="report.search"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
          />
        </v-col>
        <v-col class="ta-right">
          <v-btn
            v-if="$store.state.profile.org_reporting_edit"
            @click="openNewReportFolderDialog()"
            class="mr-4"
            color="primary"
            elevation="0"
            prepend-icon="add"
          >
            {{ $t('New folder') }}
          </v-btn>
          <v-btn
            v-if="$store.state.profile.org_reporting_edit"
            @click="dialog = true"
            color="primary"
            elevation="0"
            prepend-icon="add"
          >
            {{ $t('New report') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <ReportFolders
      @open-dialog="$refs.reportFolderDialog.open($event)"
      :destroy-report-folder="destroyReportFolder"
      :is-any-report-folder-selected="isAnyReportFolderSelected()"
      :reload="load"
      :report-folders="reportFolders"
    />

    <v-container
      class="px-3 py-0"
      data-testid="reportList"
      fluid
    >
      <v-card
        v-if="reports.length > 0"
        class="bb-1 bc-very-light-grey pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row data-testid="reportList.header">
            <v-col
              class="fw-600 fs-16 c-black d-none d-md-flex"
              cols="3"
            >
              {{ $t('Name') }}
            </v-col>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Type') }}
            </v-col>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Schema') }}
            </v-col>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Created') }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <NullState
        v-else
        @new="dialog = true"
        :hide-new-button="!$store.state.profile.org_reporting_edit"
        title="No reports found"
      />

      <v-card
        v-for="(reportItem, index) in reports"
        :key="index"
        :to="{ name: 'ReportShow', params: { reportId: reportItem.id } }"
        class="pointer bt-0 bc-very-light-grey pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col
              class="c-black fs-16 fw-500"
              cols="3"
              data-cy="report-name"
            >
              <span v-text="reportItem.name" />
            </v-col>

            <v-col class="c-black fs-16 fw-500">
              <span v-text="getDataType(reportItem.data_type)" />
            </v-col>

            <v-col class="c-black fs-16 fw-500">
              {{ $t(reportItem.data_type) }}
            </v-col>

            <v-col class="c-black fs-16 fw-500">
              <LongDate :date="reportItem.created_at" />
            </v-col>

            <v-col
              cols="1"
              data-cy="kebab"
            >
              <ActionMenu
                v-if="$store.state.profile.org_reporting_admin"
                @click:action:delete="openDestroyReportDialog(reportItem)"
                @click:action:move="openMoveReportDialog(reportItem)"
                :items="getReportActions()"
                button-class="ms-3"
                button-icon="more_vert"
                x-small
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer :min-height="50" />

      <v-dialog
        v-model="dialog"
        scrim="transparent"
        width="400px"
      >
        <v-card
          v-if="schemas"
          border
          flat
          tile
        >
          <v-card-title>{{ $t('New report') }}</v-card-title>

          <v-divider class="mb-3" />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="report.name"
                message="Name"
              />

              <LabeledAutocomplete
                v-model="report.data_type"
                :items="dataTypes"
                :multiple="false"
                message="Report type"
              />

              <LabeledAutocomplete
                v-if="showSchemaSelector"
                v-model="report.schema_id"
                :items="relevantSchemas"
                :multiple="false"
                item-title="name"
                item-value="id"
                message="Schema"
              />
            </v-row>
          </v-card-text>

          <v-divider class="mb-3" />

          <v-card-actions>
            <v-btn
              @click="dialog = false"
              :loading="processing"
              color="primary"
              size="x-large"
              variant="text"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="create"
              :disabled="!report.name || !report.data_type"
              :loading="processing"
              color="primary"
              size="x-large"
            >
              {{ $t('Create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <ReportFolderDialog
      ref="reportFolderDialog"
      :after-save="load"
      :api="api"
      :processing="processing"
      :reports="reports"
      :teams="teams"
    />
    <ReportFolderMoveDialog
      ref="moveReportDialog"
      :after-save="load"
      :processing="processing"
      :report-folder-items="reportFolders"
      :selected-report="currentReport"
    />
    <DecisionDialog
      @confirm="deleteReport"
      ref="deleteReportDialog"
      :after-save="load"
      :content="reportDeleteDialogContent()"
      :processing="processing"
      confirm-button-text="Delete Report"
      title="Delete Report"
    />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ReportFolderMoveDialog from '@/specialist/components/report-folders/ReportFolderMoveDialog.vue';
import ReportFolderDialog from '@/specialist/components/report-folders/ReportFolderDialog.vue';
import ReportFolders from '@/specialist/components/report-folders/ReportFolders.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  api: {
    type: Object,
    default: () => Api,
  },
});

const currentReport = ref(null);
const dataTypes = ref([]);
const dialog = ref(false);
const deleteReportDialog = ref(null);
const moveReportDialog = ref(null);
const page = ref(1);
const pages = ref(1);
const processing = ref(false);
const query = ref(null);
const queryCount = ref(0);
const report = ref({ data_type: null, name: null, schema_id: null });
const reportFolderDialog = ref(null);
const reportFolders = ref([]);
const reports = ref([]);
const schemas = ref([]);
const teams = ref([]);

const relevantSchemas = computed(() => {
  if (report.value.data_type) {
    return schemas.value.filter(relevantSchema);
  }

  return [];
});

const showSchemaSelector = computed(() => {
  return (
    report.value.data_type &&
    dataTypes.value.find((dt) => dt.value == report.value.data_type).schematized
  );
});

watch(page, () => load());
watch(query, () => load());
watch('report.value.data_type', () => (report.value.schema_id = null));

watch(
  () => route.query,
  async () => {
    processing.value = true;
    await load();
    processing.value = false;
  },
  {
    immediate: true,
  },
);

function buildReportIndexParams() {
  const params = {
    order: 'created',
    page: page.value,
    query: query.value,
  };

  if (_.isEmpty(query.value)) {
    params.report_folder_id = route.query.reportFolderId;
    params.scoped_to_folder = isAnyReportFolderSelected();
  }

  return params;
}

function create() {
  processing.value = true;

  props.api.organization.report.create(
    report.value,
    (resp) => {
      router.push({ name: 'ReportShow', params: { reportId: resp.data.id } });
    },
    (err) => {
      processing.value = false;
      eventBus.$emit('error', err);
    },
  );
}

async function destroyReportFolder(folder) {
  processing.value = true;
  await props.api.organization.reportFolder.destroy(folder || getSelectedReportFolder());

  if (folder) {
    await load();
  } else {
    router.push({ name: 'ReportIndex' });
    await loadReports();
  }

  processing.value = false;
}

function getReportFolderActionItems() {
  return [
    { event: 'edit', avatar: 'edit', title: 'Edit folder' },
    { event: 'delete', avatar: 'delete', title: 'Delete folder' },
  ];
}

function getReportActions() {
  return [
    { event: 'move', avatar: 'edit', title: 'Move' },
    { event: 'delete', avatar: 'delete', title: 'Delete' },
  ];
}

function getSelectedReportFolder() {
  return (
    _.find(
      reportFolders.value,
      (reportFolder) => reportFolder?.id?.toString() === route?.query?.reportFolderId?.toString(),
    ) || {}
  );
}

function getDataType(dataType) {
  return _.find(dataTypes.value, { value: dataType })?.text;
}

function getTitle() {
  return isAnyReportFolderSelected() ? getSelectedReportFolder().name : 'Reports';
}

function isAnyReportFolderSelected() {
  return !_.isNil(route.query?.reportFolderId);
}

async function load() {
  processing.value = true;
  reports.value = [];
  queryCount.value += 1;

  if (dataTypes.value.length === 0) await loadReportDataTypes();
  await loadReports();
  await loadReportFolders();
  await loadSchemas();

  processing.value = false;
}

async function loadReportDataTypes() {
  dataTypes.value = (await props.api.organization.reportDataType.index()).data;
}

async function loadReports() {
  const currentQueryCount = queryCount.value;
  const response = await props.api.organization.report.index(buildReportIndexParams());

  if (queryCount.value === currentQueryCount) {
    pages.value = parseInt(response.headers['x-page-count'] || 1, 10);
    reports.value = response.data;
  }
}

async function loadReportFolders() {
  const response = await props.api.organization.reportFolder.index({
    include: ['reports', 'teams'],
  });

  reportFolders.value = response.data;
}

async function loadSchemas() {
  const response = await props.api.public_api.organization.schema.index();
  schemas.value = response.data;
}

async function openNewReportFolderDialog() {
  await reportFolderDialog.value.open(getSelectedReportFolder());
}

async function openEditReportFolderDialog() {
  await reportFolderDialog.value.open(getSelectedReportFolder());
}

async function openMoveReportDialog(report) {
  currentReport.value = report;
  await moveReportDialog.value.open(report);
}

async function openDestroyReportDialog(report) {
  currentReport.value = report;
  await deleteReportDialog.value.open();
}

function relevantSchema(schema) {
  if (!schema.id) return false;

  return (
    report.value.data_type === schema.data_type ||
    (report.value.data_type === 'ChildData' && schema.data_type === 'Child') ||
    (report.value.data_type === 'GroupData' && schema.data_type === 'Group')
  );
}

function reportDeleteDialogContent() {
  const reportName = currentReport?.value?.name;

  return `Are you sure you want to delete the "${reportName}" report? This action cannot be undone.`;
}

async function deleteReport() {
  const resp = await Api.organization.report.destroy(currentReport.value.id);

  if (resp.status === 200) {
    router.push({ name: 'ReportIndex' });
    await loadReports();
  } else {
    eventBus.error('Error occurred');
  }

  await deleteReportDialog.value.close();
  eventBus.chime('Report was deleted');
}
</script>
