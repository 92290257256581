<template>
  <div class="d-flex fill-height w-100pc justify-center">
    <div v-if="dataSchema && form && provider && schema">
      <v-toolbar
        class="bc-outlined-gray bb-1"
        elevation="0"
        style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
        absolute
      >
        <v-row class="align-center px-4">
          <v-col
            class="d-flex align-center"
            cols="8"
            md="8"
          >
            <v-btn
              v-if="backRoute"
              :to="backRoute"
              class="me-3"
              variant="text"
              icon
            >
              <v-icon
                class="c-black"
                icon="west"
                size="28"
              />
            </v-btn>

            <div
              data-cy="page-title"
              data-testid="page-title"
            >
              <span class="d-inline-block fs-22 fw-600">
                {{ form.name }}
              </span>
            </div>

            <v-chip
              class="ms-2"
              color="primary"
              size="large"
              variant="flat"
            >
              {{ form.status }}
            </v-chip>

            <v-tooltip
              v-if="!readonly"
              location="bottom"
            >
              <template #activator="{ props }">
                <v-btn
                  @click="toggleLock"
                  v-bind="props"
                  :icon="form.locked ? 'lock' : 'lock_open'"
                  class="ms-3 me-2"
                  color="primary"
                  data-cy="lock-button"
                  size="small"
                  variant="text"
                />
              </template>

              {{ $t(form.locked ? 'Unlock form' : 'Lock form') }}
            </v-tooltip>
          </v-col>

          <v-col
            class="d-flex align-center justify-end"
            cols="4"
            md="4"
          >
            <ActionMenu
              v-if="$store.state?.profile?.org_forms_edit || $store.state?.profile?.org_forms_admin"
              @click:action:status="setStatus"
              :button-color="buttonColor"
              :button-title="form.status || 'Draft'"
              :dark="reviewed || null"
              :items="statusActions"
              button-icon-side="right"
              button-outlined
              left
            />

            <ActionMenu
              @click:action:delete="deleteForm"
              :items="actionItems"
              button-icon="more_vert"
              x-small
            />
          </v-col>
        </v-row>
      </v-toolbar>

      <div class="bg-super-light-blue fill-height pt-1">
        <div
          class="fill-height px-3"
          style="overflow-y: auto; padding-top: 70px"
        >
          <v-card
            border
            tile
          >
            <QuestionSet
              v-model="dataModel"
              @change="saveForm($event)"
              @change:attachments="loadAttachments()"
              :attachment-group-id="form.group_id"
              :attachment-owner-id="form?.id"
              :attachment-owner-type="'Form'"
              :attachment-readonly="readonly || null"
              :attachments="attachments"
              :processing="processing"
              :questions="validQuestions"
              :readonly="readonly"
              :schema="dataSchema.definition"
              color="white"
              expanded
              hide-actions
              landscape
              very-dense
            />

            <div
              v-for="question in validVerificationQuestions"
              :key="question.id"
            >
              <v-divider class="my-2" />

              <FormQuestion
                :subtitle="question.verification_subtitle"
                :title="question.verification_title"
                color="white"
                hide-actions
                landscape
                very-dense
              >
                <AttachmentUploader
                  v-show="!readonly"
                  @uploaded="attachments.push($event)"
                  :ref="['uploader', question.id].join('')"
                  :owner="{
                    type: 'Form',
                    id: form.id,
                    tag: question.id,
                    tags: [form.id, question.id],
                  }"
                  class="mb-4"
                />

                <AttachmentList
                  @delete="loadAttachments()"
                  :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                  :hide-remove="readonly"
                  class="mb-6"
                />
              </FormQuestion>
            </div>

            <v-row
              v-if="displaySubmitButton"
              class="ma-1"
            >
              <v-col>
                <v-btn
                  @click="submitForm()"
                  :disabled="questionsIncomplete"
                  class="mt-2"
                  color="primary"
                  block
                >
                  {{ $t('Submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </div>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const UPPY_CLASS_NAME = 'uppy-Dashboard-input';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const attachments = ref([]);
const confirmDialog = ref(null);
const form = ref(null);
const processing = ref(false);
const provider = ref(null);
const schema = ref(null);

const schemaId = computed(() => schema.value?.id);

const {
  questionsIncomplete,
  questionsLoaded,
  validQuestions,
  validVerificationQuestions,
  validateAnswers,
} = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: schemaId,
  syncedObjectRef: form,
});

const actionItems = computed(() => {
  if (isReadonlyEnrollmentForm.value) return [];

  if (store.state.profile.org_forms_admin || store.state.profile.org_forms_delete) {
    return [
      {
        event: 'delete',
        avatar: 'delete',
        title: 'Delete',
      },
    ];
  }

  return [];
});

const backRoute = computed(() => {
  if (route.query?.back === 'subsidy') {
    return {
      name: 'SubsidyShow',
      params: {
        id: form.value?.subsidy_id,
      },
    };
  } else if (route.query?.back === 'provider') {
    return {
      name: 'ProviderShow',
      params: {
        providerId: form.value?.provider_id,
      },
    };
  } else {
    return { name: 'FormIndex' };
  }
});

const buttonColor = computed(() => {
  if (form.value.status === 'Approved') return 'green';
  if (form.value.status === 'Denied') return 'red';

  return 'primary';
});

const dataModel = computed(() => {
  if (dataSchema.value) {
    if (dataSchema.value.data_type === 'Provider') return provider.value;

    return form.value;
  }

  return null;
});

const dataSchema = computed(() => {
  if (provider.value && questionsLoaded.value) {
    if (validQuestions.value[0]?.synced_model === 'Provider') {
      return store.state.schemas[provider.value.schema_id];
    }

    if (schema.value) return schema.value;
  }

  return null;
});

const displaySubmitButton = computed(() => {
  if (form.value.submitted_at) return false;

  return store.state.profile.org_forms_edit || store.state.profile.org_forms_admin;
});

const isReadonlyEnrollmentForm = computed(() => {
  if (!form.value?.enrollment_id) return false;

  return store.state.profile.org_enrollments_agent;
});

const readonly = computed(() => {
  if (isReadonlyEnrollmentForm.value) return true;

  return !(store.state.profile.org_forms_edit || store.state.profile.org_forms_admin);
});

const reviewed = computed(() => ['Approved', 'Denied'].includes(form.value.status));

const statusActions = computed(() => {
  if (readonly.value) return [];

  return [
    {
      event: 'status',
      avatar: 'done',
      title: form.value.status === 'Approved' ? 'Approved' : 'Approve',
    },
    {
      event: 'status',
      avatar: 'remove_done',
      title: form.value.status === 'Denied' ? 'Denied' : 'Deny',
    },
  ];
});

watch(
  () => route.params.formId,
  () => void load(),
  { immediate: true },
);

async function deleteForm() {
  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to delete this form? This cannot be undone.`,
  );

  if (!confirm) return;

  const result = await Api.organization.form.destroy(form.value.id);

  eventBus.chime(result.status === 200 ? 'Deleted successfully' : result.error);

  await router.replace(backRoute.value);
}

async function load() {
  const { data } = await Api.organization.form.get(route.params.formId);
  form.value = data;

  schema.value = store.state.schemas[form.value.schema_id];

  void loadAttachments();
  loadProvider();
}

async function loadAttachments() {
  const params = {
    owner_type: 'Form',
    owner_id: route.params.formId,
  };

  const response = await Api.organization.attachment.index(params);
  if (response?.status !== 200) return;

  attachments.value = response.data;
}

function loadProvider() {
  if (form.value.provider_id) {
    Api.organization.provider.get(form.value.provider_id, (response) => {
      provider.value = response.data;
    });
  } else {
    provider.value = {};
  }
}

async function saveForm(event) {
  const isUppyTriggeredEvent = event.target?.className === UPPY_CLASS_NAME;

  if (!readonly.value && !isUppyTriggeredEvent) {
    processing.value = true;

    if (form.value.provider_id) {
      Api.organization.provider.update(
        provider.value.id,
        provider.value,
        () => {
          void updateForm();
        },
        (error) => {
          processing.value = false;
          eventBus.chime(error.response.data.errors[0]);
        },
      );
    } else {
      void updateForm();
    }

    validateAnswers();
  }
}

async function setStatus(newVal) {
  let status;

  if (newVal.title === 'Approve') {
    status = 'Approved';
  } else if (newVal.title === 'Deny') {
    status = 'Denied';
  }

  const params = { status };
  await Api.organization.form.update(route.params.formId, params);
  void load();
}

async function submitForm() {
  const params = { submitted: true };
  await Api.organization.form.update(route.params.formId, params);

  eventBus.chime('Form submitted');
  void load();
}

async function toggleLock() {
  const params = { locked: !form.value.locked };
  await Api.organization.form.update(route.params.formId, params);

  eventBus.chime(params.locked ? 'Form locked' : 'Form unlocked');
  void load();
}

async function updateForm() {
  await nextTick();

  Api.organization.form.update(
    form.value.id,
    form.value,
    (response) => {
      processing.value = false;
      form.value = response.data;
      eventBus.chime('Form saved');
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}
</script>
